import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";
import { Box } from "@mui/material";

const style = {
  root: {
    width: 300,
    display: "flex",
    alignItems: "center",
    height: 74,
  },
  link: {
    color: theme.palette.black.coolGrey,
    "&:hover": {
      color: theme.palette.black.darkSeaBlack,
      cursor: "pointer",
    },
  },
};

const PilotSearchBackNav = ({ onClick }) => {
  return (
    <Box sx={style.root}>
      <Box onClick={onClick} sx={style.link}>
        <ArrowBackIcon fontSize="medium" />
      </Box>
    </Box>
  );
};

PilotSearchBackNav.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default PilotSearchBackNav;
