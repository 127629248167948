import { Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import AppButton from "../../../../global/elements/AppButton";
import Dialogue from "../../../../global/Dialogue";
import AppContext from "../../../../../contexts/AppContext";
import { updateClient } from "../../../../../services/client";
import FeedbackDialogueBody from "./FeedbackDialogueBody";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { updateMission } from "../../../../../services/missions";
import { daysFromTodayMillis } from "../../../../../lib/helpers";
import {
  notificationType,
  orderCollectionData,
  userRoles,
} from "src/lib/constants";
import { updateProject } from "src/services/project";
import { createNotification } from "src/services/notification";
import { getUser } from "src/services/auth";
import firebase from "src/firebase";

const styles = {
  rootContainer: {
    padding: "30px 15px",
  },
};

const CompleteFeedback = ({
  feedbackPopupInitialState,
  dataML,
  dataSL,
  checkFeedBackStatus,
  setFeedbackPopupInitialState,
}) => {
  const appContext = useContext(AppContext);
  const userId = appContext.userData.id;
  const isClient = getUser("user").role === userRoles.client;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isAnyDataSubmitted, setIsAnyDataSubmitted] = useState(0);

  const [openFeedbackPopup, setOpenFeedbackPopup] = useState(
    feedbackPopupInitialState
  );

  const openCloseFeedbackPopupFcn = async () => {
    setLoading(true);
    await checkFeedBackStatus(userId, true);
    if (dataML?.length === 0 || dataSL?.length === 0) {
      enqueueSnackbar("You have already rated all of your orders!!!", {
        variant: "success",
      });
    }
    setLoading(false);
    // setOpenFeedbackPopup(!openFeedbackPopup);
  };

  const submitFeedback = async (value, id, orderType, orderName) => {
    if (value > 0) {
      setIsAnyDataSubmitted((prev) => prev + 1);
    } else {
      setIsAnyDataSubmitted((prev) => prev - 1);
    }
    const dataToUpdate = {
      overAllExperience: value,
      qualityOfService: 0,
      qualityOfData: 0,
      valueForMoney: 0,
      // recommendationLikelihood: 0,
      returningLikelihood: 0,
      clientFeedback: 0,
    };
    if (orderType === orderCollectionData.missions) {
      updateMission("", id, dataToUpdate);
    } else {
      updateProject({
        projectId: id,
        updates: dataToUpdate,
      });
    }
    const subHeading = `Client has submiited a feedback for the ${orderName} order.`;
    const heading = "Feedback has been submitted by a client.";
    const actionLink =
      orderType === orderCollectionData.missions
        ? `/app/missions/${id}#client-feedback`
        : `/app/admin/projects/${id}#client-feedback`;
    if (isClient) {
      createNotification(
        firebase,
        notificationType.adminNotification,
        subHeading,
        heading,
        actionLink
      );
    }
  };

  const permanentSkipFcn = () => {
    setOpenFeedbackPopup(false);
    setFeedbackPopupInitialState(false);
    if (userId) {
      updateClient(userId, {
        feedbackSkipped: true,
        partialSkippedDate: 0,
      });
    }
  };
  const remindMeLaterFcn = () => {
    setOpenFeedbackPopup(false);
    setFeedbackPopupInitialState(false);
    if (userId) {
      updateClient(userId, {
        feedbackSkipped: false,
        partialSkippedDate: daysFromTodayMillis(7),
      });
    }
  };
  const submitFeedbackSuccess = () => {
    if (isAnyDataSubmitted > 0) {
      setOpenFeedbackPopup(false);
      setFeedbackPopupInitialState(false);
      enqueueSnackbar("Thank you for your valuable feedback!!!", {
        variant: "success",
      });
      updateClient(userId, {
        feedbackSkipped: false,
        partialSkippedDate: daysFromTodayMillis(3),
      });
    } else {
      enqueueSnackbar("Submission failed: No rating provided!", {
        variant: "error",
      });
    }
  };

  const dialogueBody = () => {
    return (
      <FeedbackDialogueBody
        singleLocData={dataSL}
        multiLocData={dataML}
        submitFeedBackFcn={submitFeedback}
        remindMeLaterFcn={remindMeLaterFcn}
        permanentSkipFcn={permanentSkipFcn}
        submitFeedbackSuccess={submitFeedbackSuccess}
      />
    );
  };

  return (
    <>
      <Grid container sx={styles.rootContainer}>
        <Grid item container xs={12} md={8} lg={10}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Your feedback is valuable to us.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              We strive for excellence in everything we do and value your
              insights. Your feedback helps us enhance our services and deliver
              the best possible experience.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <AppButton
            label="Give feedback"
            look="green"
            submittingState={loading}
            onClick={openCloseFeedbackPopupFcn}
          />
        </Grid>
      </Grid>
      <Dialogue
        showDialogue={openFeedbackPopup}
        setShowDialogue={setOpenFeedbackPopup}
        DialogueBody={dialogueBody()}
        showCancelButton={false}
        showSubmitButton={false}
        bottomDeviderLine={true}
        maxWidth="lg"
        additionalStyle={{
          overflowY: "hidden", // Scrollable content
        }}
      />
    </>
  );
};

CompleteFeedback.propTypes = {
  feedbackPopupInitialState: PropTypes.bool,
  dataSL: PropTypes.array.isRequired,
  dataML: PropTypes.array.isRequired,
  checkFeedBackStatus: PropTypes.func.isRequired,
  setFeedbackPopupInitialState: PropTypes.func.isRequired,
};

CompleteFeedback.defaultProps = {
  feedbackPopupInitialState: false,
};

export default CompleteFeedback;
