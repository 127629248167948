import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import GlobalTableValueConvertHandler from "../elements/GlobalTableValueConverthandler";
import PropTypes from "prop-types";

function TableWithoutPagination({ datas, columns, customFields }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns &&
                columns?.map((column, i) => (
                  <TableCell key={"label" + i}>{column.label}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {datas?.map((data) => (
              <TableRow key={data.id}>
                {columns &&
                  columns?.map((column, i) =>
                    column.custom ? (
                      <TableCell key={column.id}>
                        {customFields(column, data)}
                      </TableCell>
                    ) : column?.field ? (
                      <TableCell key={column.id}>
                        <GlobalTableValueConvertHandler
                          column={column.id}
                          field={column.field}
                          value={data}
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={"data" + i}>{data[column.id]}</TableCell>
                    )
                  )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

TableWithoutPagination.propTypes = {
  columns: PropTypes.object.isRequired,
  customFields: PropTypes.func,
  datas: PropTypes.object.isRequired,
};

TableWithoutPagination.defaultProps = {
  customFields: undefined,
};

export default TableWithoutPagination;
