import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import theme from "../../../../../theme";

const styles = {
  radioBox: {
    display: "flex",
    flexDirection: "row",
  },
  typographyStyle: {
    color: theme.palette.grey.warmGrey,
    marginBottom: "1em",
  },
  radioIconStyles: {
    color: theme.palette.primary.main,
  },
};

const OrderMenu = ({
  title = undefined,
  content = undefined,
  contentRight = undefined,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={styles.typographyStyle}>
          {content}
        </Typography>
      </Grid>

      {contentRight && (
        <Grid item xs={6}>
          <Box sx={styles.radioBox}>
            <RadioButtonCheckedOutlinedIcon
              sx={styles.radioIconStyles}
              fontSize="medium"
            />
            <Typography variant="body1" ml={1}>
              {contentRight}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

OrderMenu.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  contentRight: PropTypes.string,
};
// OrderMenu.defaultProps = {
//   contentRight: undefined,
// };

export default OrderMenu;
