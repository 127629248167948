import firebase from "src/firebase";
import { getCurrentDateTime } from "../lib/helpers";
import { missionStatus, missionTypeConstant } from "../lib/constants";

export const createFeedback = (feedbackData) => {
  return new Promise((resolve, reject) => {
    const missionsDoc = firebase.firestore().collection("feedbacks").doc();

    const currentTime = getCurrentDateTime();
    missionsDoc
      .set({
        rating: feedbackData.rating,
        feedbackType: feedbackData.FeedbackType,
        description: feedbackData.description,
        email: feedbackData.email,
        name: feedbackData.name,
        pilotId: feedbackData.id,
        dateCreated: currentTime,
        feedbackBy: feedbackData.feedbackBy,
        company: feedbackData.company,
        phone: feedbackData.phone,
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
        return reject(error);
      });
  }).then((res) => {
    return res;
  });
};

const mapFirebaseDataToFeedbacks = (id, data) => {
  return {
    ...data,
    id,
  };
};

export const getFeedbacks = (firebase, lastVisible, limit) => {
  let query = firebase
    .firestore()
    .collection("feedbacks")
    .orderBy("dateCreated", "desc");
  if (lastVisible) {
    query = query.startAfter(lastVisible);
  }
  return query
    .limit(limit)
    .get()
    .then((snapshop) => {
      const feedbackDatas = snapshop.docs.reduce((acc, doc) => {
        const data = doc.data();
        const feedbackData = mapFirebaseDataToFeedbacks(doc.id, data);
        acc.push(feedbackData);
        return acc;
      }, []);
      return {
        feedbackDatas,
        lastVisible:
          snapshop.size > 0 ? snapshop.docs[snapshop.docs.length - 1] : null,
        last: snapshop.size < limit,
      };
    });
};

export const getSingleFeedback = (firebase, id) => {
  return firebase
    .firestore()
    .collection("feedbacks")
    .doc(id)
    .get()
    .then((feedback) => {
      if (feedback.exists) {
        return mapFirebaseDataToFeedbacks(feedback.id, feedback.data());
      }
      return Promise.error("Feedback not found");
    });
};

export const getAllOrderForFeedback = async (clientId) => {};
