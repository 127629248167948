import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import firebase from "src/firebase";
import { getPilots } from "src/services/pilots";
import { baseFilters } from "../lib/constants";

const useGetPilotList = (
  initalSortBy = ["dateCreated", "desc"],
  initialFilterValues = [baseFilters, false]
) => {
  const [pilotList, setPilotList] = useStateIfMounted([]);
  const [allPilots, setAllPilots] = useStateIfMounted([]);
  const [filteredPilotList, setFilteredPilotList] = useStateIfMounted([]);
  const [pilotsLoading, setPilotsLoading] = useStateIfMounted(true);
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);

  const [isLastPilot, setIsLastPilot] = useStateIfMounted({
    isLast: false,
    lastPageNumber: null,
  });
  const [lastVisiblePilot, setLastVisiblePilot] = useStateIfMounted(null);
  const [currentPageNumber, setCurrentPageNumber] = useStateIfMounted(1);
  const [previousPageNumber, setPreviousPageNumber] = useStateIfMounted(0);
  const [allPilotData, setAllPilotData] = useStateIfMounted([]);
  const [filterBy, setFilterBy] = useStateIfMounted(initialFilterValues);
  const [filterOrSortTriggered, setFilterorSortTriggered] =
    useStateIfMounted(false);
  const [limit, setLimit] = useStateIfMounted(10);

  useEffect(() => {
    if (!firebase) return;
    getPilots(
      firebase,
      undefined,
      undefined,
      undefined,
      sortBy[1],
      sortBy[0],
      filterBy,
      undefined
    )
      .then((res) => {
        setAllPilots(res.pilots);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setPilotsLoading(false));
    if (
      isLastPilot.isLast &
      (isLastPilot.lastPageNumber == currentPageNumber - 1)
    )
      return;
    if (
      (currentPageNumber <= previousPageNumber) &
      (filterOrSortTriggered === false)
    ) {
      let prevPagePilotData = allPilotData.slice(
        (currentPageNumber - 1) * limit,
        currentPageNumber * limit
      );
      setPilotList(prevPagePilotData);
      setPilotsLoading(false);
    } else {
      if (filterOrSortTriggered) {
        setLastVisiblePilot(null);
        setCurrentPageNumber(1);
        setPreviousPageNumber(0);
      }
      getPilots(
        firebase,
        lastVisiblePilot,
        currentPageNumber,
        previousPageNumber,
        sortBy[1],
        sortBy[0],
        filterBy,
        limit
      )
        .then((res) => {
          if (res.last) {
            setIsLastPilot({
              isLast: true,
              lastPageNumber: currentPageNumber,
            });
          } else {
            setIsLastPilot({
              isLast: false,
              lastPageNumber: null,
            });
          }
          if (filterOrSortTriggered) {
            setPreviousPageNumber(0);
            setAllPilotData([...res.pilots]);
          } else {
            setAllPilotData([...allPilotData, ...res.pilots]);
          }
          setLastVisiblePilot(res.lastVisible);
          setPreviousPageNumber(currentPageNumber);
          setPilotList(res.pilots);
          setFilterorSortTriggered(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setPilotsLoading(false));
    }
  }, [currentPageNumber, sortBy, filterBy, limit]);

  useEffect(() => {
    let newPilotList = [...pilotList];
    setFilteredPilotList(newPilotList);
  }, [sortBy, pilotList]);

  return [
    filteredPilotList,
    pilotsLoading,
    sortBy,
    currentPageNumber,
    isLastPilot,
    setSortBy,
    setCurrentPageNumber,
    setFilterorSortTriggered,
    setFilterBy,
    setLimit,
    filterBy,
    limit,
    allPilots,
  ];
};

export default useGetPilotList;
