import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import theme from "src/theme";

const ratingRemarks = ["Poor", "Not Great", "Neutral", "Good", "Excellent"];
const priceRatingRemarks = [
  "Very overpriced",
  "Not good pricing",
  "Average pricing",
  "Good pricing",
  "Excellent low pricing",
];

const RatingRemarks = ({ rating, ratingField }) => {
  return (
    <Typography variant="body3" sx={{ color: theme.palette.black.coolGrey }}>
      {rating === 0
        ? "Not yet rated"
        : ratingField === "priceRating"
        ? priceRatingRemarks[Math.round(rating) - 1]
        : ratingRemarks[Math.round(rating) - 1]}
    </Typography>
  );
};
RatingRemarks.propTypes = {
  ratingField: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
};
export default RatingRemarks;
