import React from "react";
import { Box, Typography } from "@mui/material";
import GlobheLogo from "src/assets/images/feedback-success.png";
import PropTypes from "prop-types";

const styles = {
  emptyDataContainer: {
    textAlign: "center",
    justifyContent: "center",
    alignItem: "center",
  },

  logo: {
    width: "100px",
    borderRadius: "0px",
  },
};

const EmptyDataIndicator = ({ title, subTitle }) => {
  return (
    <Box sx={styles.emptyDataContainer} mt={15}>
      <Typography variant="h4" mb={2}>
        {title}
      </Typography>

      <Box
        src={GlobheLogo}
        sx={styles.logo}
        component="img"
        alt="Globhe Logo"
        mb={2}
      />
      <Typography variant="h5">{subTitle}</Typography>
    </Box>
  );
};

EmptyDataIndicator.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default EmptyDataIndicator;
