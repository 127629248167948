import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../../theme";
import GlobalTable from "../../admin/GlobalTable";
import PropTypes from "prop-types";
import StatusBadge from "../../../global/elements/StatusBadge";
import {
  OrderTypeConstant,
  areaUnitV2,
  areaUnits,
  colorStatus,
  missionFeasibility,
  missionPlanningType,
  userRoles,
} from "../../../../lib/constants";
import AppButton from "../../../global/elements/AppButton";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import { getUser } from "../../../../services/auth";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import ChangeFeasibiltyPopup from "./ChangeFeasibiltyPopup";
import { createMissionPlanningObj } from "../../../../services/missions";

const styles = {
  projectDroneOperatorsMainContainer: {
    marginTop: "40px !important",
  },
  contentBox: {
    boxShadow: "none",
    filter: `drop-shadow(0 3pt 66pt ${theme.palette.grey.lightSpray})`,
    background: theme.palette.grey.white,
    marginTop: "20px",
  },
  blackText: {
    color: `${theme.palette.black.darkSeaBlack} !important`,
  },
  noDataText: {
    textAlign: "center",
    margin: "20px",
    padding: "40px 20px",
    color: theme.palette.secondary.main,
  },
  warningText: {
    color: theme.palette.status.warningDark,
  },
};

const ProjectTableDetails = ({
  loading = true,
  allData = [],
  noDataAvailableText = "",
  onClick = undefined,
  projectId = undefined,
  triggerReload = undefined,
  setTriggerReload = undefined,
  enableMarkAllRead = false,
  title = undefined,
  columns = undefined,
}) => {
  const [dataToShow, setDataToShow] = useState([]);
  const [limit, setLimit] = useState(5);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [orderFeasibilityPopup, setOrderFeasibilityPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [feasibilityLoading, setFeasibilityLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const currentUser = getUser("user").role;
  useEffect(() => {
    setDataToShow(allData?.slice(0, limit));
    let pageNumberNew = currentPageNumber;
    if (allData?.length <= pageNumberNew * limit) {
      setIsLastPage(true);
      setLastPageNumber(pageNumberNew);
    }
  }, [limit, allData]);

  const valueConvertHandler = (field, row) => {
    switch (field) {
      case "missionStatus": {
        const allMissionCount = row.missionCountForPilot || 0;
        const completedMissioncount = row.completedMissionCountForPilot || 0;
        return (
          <StatusBadge
            name={completedMissioncount + "/" + allMissionCount}
            status={
              completedMissioncount == allMissionCount
                ? colorStatus.green
                : colorStatus.red
            }
          />
        );
      }

      case "areaSize": {
        let area = row?.areaSize || 0;
        area = Number.isInteger(area) ? area : area.toFixed(2);

        if (row?.type == OrderTypeConstant.area) {
          area = area + " " + areaUnits.sqkm;
        } else if (row?.type == OrderTypeConstant.linear) {
          area = area + " " + areaUnitV2.km;
        } else {
          area = "N/A";
        }

        return <Typography variant="body1">{area}</Typography>;
      }

      case "action": {
        return onClick ? (
          <AppButton
            look="negative"
            small
            noIcon
            onClick={() => onClick(row)}
            label="Delete"
          />
        ) : (
          <></>
        );
      }

      case "switchDropdown": {
        return (
          <Box>
            <SwitchSelect
              checked={!row?.notFeasible}
              handleChange={() => {
                setOrderFeasibilityPopup(true);
                setSelectedRow(row);
              }}
              name="notShowInDataLibrary"
              disabled={currentUser === userRoles.client || !row.assignedPilot}
            />
            {row.notFeasible && (
              <Typography mt={1} variant="body2" style={styles.warningText}>
                {row.notFeasibleReason || "n/a"}
              </Typography>
            )}
          </Box>
        );
      }
    }
  };
  // const goToPageFcn = (value) => {
  // if (redirectURL === "/app/missions") {
  //   if (
  //     !(
  //       value.status === missionStatus.deleted ||
  //       value.status === missionStatus.archive ||
  //       value.status === missionStatus.rejected ||
  //       value.status === missionStatus.cancelled
  //     )
  //   ) {
  //     navigate(`${redirectURL}/${value.id}`);
  //   }
  // } else if (value?.id) {
  //   navigate(`${redirectURL}/${value.id}`);
  // }
  // };

  const limitHandler = (value) => {
    setIsLastPage(false);
    setLastPageNumber(null);
    setLimit(value);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPageNumber;
    let createDataInstance = allData;
    if (type == "next") {
      if (!(lastPageNumber === currentPageNumber)) {
        pageNumberNew = pageNumberNew + 1;
        setDataToShow(
          createDataInstance.slice(
            (pageNumberNew - 1) * limit,
            limit * pageNumberNew
          )
        );
      }
    } else {
      if (pageNumberNew > 1) {
        pageNumberNew = pageNumberNew - 1;
        setDataToShow(
          createDataInstance.slice(
            (pageNumberNew - 1) * limit,
            limit * pageNumberNew
          )
        );
      }
    }
    if (allData?.length <= pageNumberNew * limit) {
      setIsLastPage(true);
      setLastPageNumber(pageNumberNew);
    } else {
      setIsLastPage(false);
      setLastPageNumber(null);
    }
    setCurrentPageNumber(pageNumberNew);
  };

  const markAllMissionAsComplete = () => {
    setLoadingBtn(true);
    const completeAllMissionOfProject = firebase
      .functions()
      .httpsCallable("completeAllMissionOfProject");

    completeAllMissionOfProject({ projectId: projectId })
      .then(() => {
        setLoadingBtn(false);
        enqueueSnackbar("All missions are marked as completed!", {
          variant: "success",
        });
        setTriggerReload(!triggerReload);
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar("Error in completing all the missions!", {
          variant: "error",
        });
        setLoadingBtn(false);
      });
  };

  const updateOrderFeasibility = async (values) => {
    setFeasibilityLoading(true);
    let data = values;
    if (data.notFeasible) {
      data.missionFeasible = missionFeasibility?.no;
      data.detailedReason = null;
      data.selectedReason = values.notFeasibleReason || null;
      data.photoProof = [];
      data.missionFeasibilityYes = false;
      data.missionFeasibilityNo = true;
    } else {
      data.missionFeasible = missionFeasibility?.yes;
      data.detailedReason = null;
      data.selectedReason = null;
      data.photoProof = [];
      data.missionFeasibilityNo = false;
      data.missionFeasibilityYes = true;
    }
    // await updateMission(firebase, selectedRow.id, {
    //   notFeasible: data.notFeasible,
    //   notFeasibleReason: data.notFeasible ? data.notFeasibleReason : null,
    // });
    await createMissionPlanningObj(
      missionPlanningType.preFlight,
      values,
      null,
      selectedRow,
      false
    );
    setSelectedRow(null);
    setOrderFeasibilityPopup(false);
    setTriggerReload(!triggerReload);
    setFeasibilityLoading(false);
  };

  return (
    <Box sx={styles.projectDroneOperatorsMainContainer}>
      <Typography variant="h6">{title || "NO TITLE"}</Typography>
      {enableMarkAllRead && currentUser === userRoles.admin && (
        <Box sx={{ float: "right" }} mb={1}>
          <AppButton
            label="Mark all mission as complete"
            look="ghost"
            onClick={() => markAllMissionAsComplete()}
            submittingState={loadingBtn}
            loaderColor={theme.palette.black.darkSeaBlack}
          />
        </Box>
      )}

      <Box sx={styles.contentBox}>
        {allData?.length > 0 ? (
          <GlobalTable
            columns={columns}
            allData={dataToShow}
            valueConvertHandler={valueConvertHandler}
            // goToPageFcn={goToPageFcn}
            loading={loading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPageNumber}
            isLastPage={isLastPage}
            lastPage={lastPageNumber}
          />
        ) : (
          <Typography variant="h6" sx={styles.noDataText}>
            {noDataAvailableText}
          </Typography>
        )}
      </Box>
      <ChangeFeasibiltyPopup
        orderFeasibilityPopup={orderFeasibilityPopup}
        setOrderFeasibilityPopup={setOrderFeasibilityPopup}
        onSubmit={updateOrderFeasibility}
        data={selectedRow}
        loading={feasibilityLoading}
      />
    </Box>
  );
};

ProjectTableDetails.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  allData: PropTypes.array,
  noDataAvailableText: PropTypes.string,
  // redirectURL: PropTypes.string,
  onClick: PropTypes.func,
  projectId: PropTypes.string,
  triggerReload: PropTypes.bool,
  setTriggerReload: PropTypes.func,
  enableMarkAllRead: PropTypes.func,
};

// ProjectTableDetails.defaultProps = {
//   loading: true,
//   allData: [],
//   noDataAvailableText: "",
//   // redirectURL: "#",
//   onClick: undefined,
//   projectId: undefined,
//   triggerReload: undefined,
//   setTriggerReload: undefined,
//   enableMarkAllRead: false,
// };

export default ProjectTableDetails;
