import { invitePilotToMission } from "../../services/missions";
import firebase from "src/firebase";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import React from "react";
import AppButton from "../global/elements/AppButton";
import { useStateIfMounted } from "use-state-if-mounted";
import { createNotification } from "../../services/notification";
import InvitePilotList from "../app/admin/InvitePilotList";

const styles = {
  textLabel: {
    color: theme.palette.black.coolGrey,
    fontSize: "16px",
    fontWeight: "500",
    padding: "0 20px 10px 20px",
  },
  header: {
    color: theme.palette.black.coolGrey,
    padding: "15px 20px 15px 20px",
  },
  weitht5: {
    fontWeight: "500",
  },
  contentBox: {
    color: theme.palette.black.coolGrey,
    padding: "10px 20px 25px 20px",
    flex: 1,
    overflowY: "auto",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row", rowGap: "1em" },
    justifyContent: { xs: "flex-start", sm: "space-between" },
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1em",
    margin: { xs: "0 0 1em 0", sm: "0 1em 1em 0" },
  },
};

export default function AlertDialog({
  Country = undefined,
  missionID = undefined,
  handlePopupClose = () => {},
  refreshMission = () => {},
  InvitePopupOpen = false,
  mission = {},
}) {
  const [selectedPilots, setSelectedPilots] = useStateIfMounted([]);
  const [allPilots, setAllPilots] = useStateIfMounted([]);
  async function inviteAllPilots() {
    let res = null;

    if (allPilots?.length) {
      res = await Promise.all(
        allPilots?.map(async (pilot) => {
          await invitePilotToMission(firebase, pilot?.id, mission);
          await createNotification(
            firebase,
            pilot?.id,
            `You are invited to a mission named ${mission?.missionName}.`,
            "You are invited to a mission.",
            `/app/missions/${mission?.id}`
          );
        })
      );
    } else {
      res = await Promise.all(
        selectedPilots?.map(async (pilot) => {
          await invitePilotToMission(firebase, pilot?.id, mission);
          await createNotification(
            firebase,
            pilot?.id,
            `You are invited to a mission named ${mission?.missionName}.`,
            "You are invited to a mission.",
            `/app/missions/${mission?.id}`
          );
        })
      );
    }
    if (res) {
      handlePopupClose();
      refreshMission();
      return res;
    }
  }

  return (
    <Box>
      <Dialog
        open={InvitePopupOpen}
        onClose={handlePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title" sx={styles.header}>
          <Typography variant="body1" sx={styles.weitht5}>
            Invite drone operators
          </Typography>
        </DialogTitle>
        <DialogContentText id="alert-dialog-description" sx={styles.contentBox}>
          <InvitePilotList
            country={Country}
            inviteFromCountry={true}
            selected={selectedPilots}
            setSelected={setSelectedPilots}
            setAllPilots={setAllPilots}
            mission={mission}
          />
        </DialogContentText>
        <DialogActions sx={styles.buttonContainer}>
          <Box sx={{ padding: "0px 0px 0px 20px" }}>
            {selectedPilots?.length > 0 && (
              <Typography variant="body1" sx={styles.weitht5}>
                You have selected {allPilots?.length || selectedPilots?.length}{" "}
                drone operator(s). Are you sure you want to invite all of them?
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "1em" }}>
            <AppButton
              look="inverted"
              noIcon
              onClick={() => {
                handlePopupClose();
                refreshMission();
              }}
              label="Close"
            />
            <AppButton
              onClick={() => inviteAllPilots()}
              marginLeft
              isDisabled={selectedPilots?.length > 0 ? false : true}
              look={selectedPilots?.length > 0 ? "black" : "blackDisabled"}
              label="Invite drone operators"
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

AlertDialog.propTypes = {
  Country: PropTypes.string,
  missionID: PropTypes.string,
  handlePopupClose: PropTypes.func,
  refreshMission: PropTypes.func,
  InvitePopupOpen: PropTypes.bool,
  mission: PropTypes.object,
};
