import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const styles = {
  infoIcon: {
    margin: "0 20px",
    color: theme.palette.black.stoneSilver,
    lineHeight: 0,
  },
  infoIconBlack: {
    margin: "0 20px",
    lineHeight: 0,
  },
};

const TooltipInfo = ({
  info = undefined,
  additionalStyle = undefined,
  type = undefined,
  black = false,
}) => {
  return (
    <Box sx={black ? styles.infoIconBlack : styles.infoIcon}>
      <Tooltip title={info} sx={additionalStyle}>
        {type == "outlined" ? (
          <InfoOutlinedIcon fontSize="medium" />
        ) : (
          <InfoIcon fontSize="medium" />
        )}
      </Tooltip>{" "}
    </Box>
  );
};

TooltipInfo.propTypes = {
  info: PropTypes.string.isRequired,
  additionalStyle: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  black: PropTypes.bool,
};
// TooltipInfo.defaultProps = {
//   black: false,
// };

export default TooltipInfo;
