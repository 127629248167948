import { Box } from "@mui/material";
import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import MissionContext from "./MissionContext";
import AppButton from "../../../../global/elements/AppButton";
import {
  missionStatus,
  userRoles,
  interestInviteStatus,
  PilotPackageStatus,
  paymentStatus,
  contractStatusLabel,
  missionTypeConstant,
  quoteStatus,
  missionPlanningStates,
} from "../../../../../lib/constants";
import PropTypes from "prop-types";
import { useStateIfMounted } from "use-state-if-mounted";
import RejectModal from "./RejectModal";
import { getInterestedPilotIds } from "../../../../../services/interest-invite";
import firebase from "src/firebase";
import UserNextActionsBanner from "./UserNextActionsBanner";

const UserNextActions = ({
  actionText = "Your next actions",
  actionSubText = "",
  actionSubText2 = "",
  tabNumber = undefined,
  projctMission = false,
  background = undefined,
  bigText = false,
}) => {
  const [showRejectDialog, setShowRejectDialog] = useStateIfMounted(false);
  const [interestPilotCount, setInterestPilotCount] = useState();
  const actionData = useContext(MissionContext);
  const [button1, setButton1] = useState(undefined);
  const [button2, setButton2] = useState(undefined);

  const isClient = actionData?.currentUser?.role == userRoles.client;
  const isAdmin = actionData?.currentUser?.role == userRoles.admin;
  const isPilot = actionData?.currentUser?.role == userRoles.pilot;

  useEffect(() => {
    getInterestedPilotIds(
      firebase,
      actionData?.mission?.id,
      interestInviteStatus.pilotRequested
    )
      .then((data) => {
        setInterestPilotCount(
          data?.pilotIds?.filter((ele) => {
            return ele != actionData?.currentUser?.id;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [actionData?.currentUser?.id, actionData?.mission?.id]);

  const quoteAndInvoice = useCallback(() => {
    actionData?.handleTabChange("e", 1);
  }, [actionData]);
  const checkData = useCallback(() => {
    actionData?.handleTabChange("e", tabNumber - 1);
  }, [actionData, tabNumber]);
  const downloadData = useCallback(() => {
    actionData?.handleTabChange("e", tabNumber - 1);
  }, [actionData, tabNumber]);
  const pilotUpload = useCallback(() => {
    actionData?.handleTabChange("e", tabNumber - 1);
  }, [actionData, tabNumber]);
  const pilotSendInvoice = useCallback(() => {
    actionData?.handleTabChange("e", 1);
  }, [actionData]);
  const goToMissionPlanningTab = useCallback(() => {
    actionData?.handleTabChange("e", tabNumber);
  }, [actionData, tabNumber]);

  const pilotActiveMissionButton = () => {
    const { missionPlanning, mission } = actionData;
    let btn1Label = null;
    let btn1Look = null;
    let btn1Click = goToMissionPlanningTab;

    let btn2Label = null;
    let btn2Look = null;
    let btn2Click = null;

    if (!missionPlanning || missionPlanning.status == "noData") {
      // Show Review mission button
      btn1Label = "Plan mission";
      btn1Look = "purple";
    } else if (missionPlanning.status == missionPlanningStates.notFeasible) {
      // Show waiting for client to review
      btn1Label = "Waiting for client review";
      btn1Look = "blackDisabled";
    } else if (missionPlanning.status == missionPlanningStates.reviewed) {
      // Show Review mission button
      btn1Label = "Review mission";
      btn1Look = "purple";
    } else if (missionPlanning.status == missionPlanningStates.onTime) {
      // Show Reschedule button
      btn1Label = "Need to reschedule mission";
      btn1Look = "purple";
    } else if (
      missionPlanning?.data?.currentFlightDate &&
      new Date(missionPlanning?.data?.currentFlightDate) < new Date() &&
      mission?.pilotPackageStatus == PilotPackageStatus.open
    ) {
      // Show Go to mission planning, mission delayed
      btn1Label = "Go to mission planning";
      btn1Look = "black";
    } else if (missionPlanning.status == missionPlanningStates.reschedule) {
      btn2Label = "Need to reschedule mission";
      btn2Look = "inverted";
      btn2Click = goToMissionPlanningTab;

      btn1Label = "All good";
      btn1Look = "purple";
      btn1Click = null;
    } else if (missionPlanning.status == missionPlanningStates.readyToUpload) {
      btn1Label = "Upload your data";
      btn1Look = "purple";
      btn1Click = pilotUpload;
    }

    if (btn1Label) {
      setButton1(
        <AppButton
          look={btn1Look}
          label={btn1Label}
          onClick={btn1Click ? btn1Click : () => {}}
          fullWidth
          noIcon
        />
      );
    }

    if (btn2Label) {
      setButton2(
        <AppButton
          look={btn2Look}
          label={btn2Label}
          onClick={btn2Click ? btn2Click : () => {}}
          fullWidth
          noIcon
        />
      );
    }
  };

  const goToInvoice = useCallback(() => {
    actionData?.handleTabChange("e", 1);
  }, [actionData]);

  const adminActiveMissionButton = () => {
    const { missionPlanning, mission } = actionData;
    let btn1Label = null;
    let btn1Look = null;
    let btn1Click = quoteAndInvoice;

    if (missionPlanning?.status == missionPlanningStates.notFeasible) {
      // Show Review location
      btn1Label = "Review location";
      btn1Look = "black";
      btn1Click = () =>
        actionData?.setPageMode(actionData?.PageModes.EDIT_MISSION);
    } else if (
      missionPlanning?.data?.currentFlightDate &&
      new Date(missionPlanning?.data?.currentFlightDate) < new Date() &&
      mission?.pilotPackageStatus == PilotPackageStatus.open
    ) {
      // Show Go to mission planning, mission delayed
      btn1Label = "Go to mission planning";
      btn1Look = "black";
    } else if (
      mission?.missionType === missionTypeConstant.clientOrder &&
      mission?.paymentStatusClient == paymentStatus.unpaid &&
      mission?.status == missionStatus.completed &&
      mission.missionType === missionTypeConstant.clientOrder
    ) {
      btn1Label = "See my invoice";
      btn1Look = "black";
    }

    if (btn1Label) {
      setButton1(
        <AppButton
          look={btn1Look}
          label={btn1Label}
          onClick={btn1Click ? btn1Click : () => {}}
          fullWidth
          noIcon
        />
      );
    }
  };

  const clientMissionButton = () => {
    const { missionPlanning, mission, quotesStatus, openCancelOrderPopup } =
      actionData;
    let btn1Label = null;
    let btn1Look = null;
    let btn1Click = goToMissionPlanningTab;

    let btn2Label = null;
    let btn2Look = null;
    let btn2Click = null;

    if (quotesStatus == quoteStatus.quoteSent) {
      btn1Label = "Quote Ready";
      btn1Look = "black";
      btn1Click = quoteAndInvoice;
    } else if (
      missionPlanning?.status == missionPlanningStates.notFeasible &&
      mission?.pilotPackageStatus == PilotPackageStatus.open
    ) {
      btn1Label = "Review location";
      btn1Look = "black";
      btn1Click = () => {
        actionData?.handleTabChange("e", tabNumber);
        actionData?.setPageMode(actionData?.PageModes.EDIT_MISSION);
      };
      btn2Label = "Cancel order";
      btn2Look = "inverted";
      btn2Click = () => {
        openCancelOrderPopup();
      };
    } else if (
      mission?.status == missionStatus.active &&
      mission?.pilotPackageStatus == PilotPackageStatus.pendingReview
    ) {
      btn1Label = "Data ready to review";
      btn1Look = "ghostDisabled";
    } else if (
      mission?.paymentStatusClient == paymentStatus.unpaid &&
      mission?.status == missionStatus.completed &&
      mission.missionType === missionTypeConstant.clientOrder
    ) {
      btn1Label = "See my invoice";
      btn1Look = "black";
      btn1Click = quoteAndInvoice;
    } else if (
      (mission?.paymentStatusClient == paymentStatus.paid ||
        mission.missionType === missionTypeConstant.projectMission) &&
      mission?.status == missionStatus.completed
    ) {
      btn1Label = "Data is available";
      btn1Look = "black";
      btn1Click = downloadData;
    }
    if (btn1Label) {
      setButton1(
        <AppButton
          look={btn1Look}
          label={btn1Label}
          onClick={btn1Click ? btn1Click : () => {}}
          fullWidth
          noIcon
        />
      );
    }

    if (btn2Label) {
      setButton2(
        <AppButton
          look={btn2Look}
          label={btn2Label}
          onClick={btn2Click ? btn2Click : () => {}}
          fullWidth
          noIcon
        />
      );
    }
  };

  useEffect(() => {
    isClient
      ? clientMissionButton()
      : // ? actionData?.totalQuote > 0 &&
      //   actionData?.mission?.status === missionStatus.initialClientOrder
      //   ? setButton1(
      //       <AppButton
      //         label={"Quote ready"}
      //         look={"black"}
      //         onClick={quoteAndInvoice}
      //         fullWidth
      //       />
      //     )
      //   : actionData?.mission?.status === missionStatus.completed
      //   ? (setButton2(
      //       <AppButton
      //         onClick={quoteAndInvoice}
      //         look="inverted"
      //         label="Pay your invoice"
      //         fullWidth
      //       />
      //     ),
      //     setButton1(
      //       <AppButton
      //         onClick={downloadData}
      //         look="black"
      //         label="Download your data"
      //         fullWidth
      //       />
      //     ))
      //   : null

      isAdmin
      ? actionData?.mission.status === missionStatus.initialClientOrder
        ? (setButton1(
            <AppButton
              look={
                actionData?.currentQuoteStatus === quoteStatus.declined
                  ? "black"
                  : "blue"
              }
              label={
                actionData?.currentQuoteStatus === quoteStatus.declined
                  ? "Create a new quote"
                  : "Create quote"
              }
              onClick={quoteAndInvoice}
              fullWidth
            />
          ),
          actionData?.mission.status != missionStatus.archive &&
            actionData?.currentQuoteStatus !== quoteStatus.declined &&
            setButton2(
              <AppButton
                look="inverted"
                label="Reject order"
                noIcon
                isDisabled={actionData?.mission.missionType ? false : true}
                onClick={() => setShowRejectDialog(true)}
                fullWidth
              />
            ))
        : actionData?.checkInviteAssignDisablity() &&
          !actionData.mission.assignedPilot
        ? setButton1(
            <AppButton
              onClick={goToInvoice}
              look="blue"
              label="Upload pre-delivery invoice"
              fullWidth
            />
          )
        : (actionData?.mission.status === missionStatus.confirmedMission ||
            actionData?.mission.status === missionStatus.unassigned) &&
          !actionData?.mission?.assignedPilot
        ? (setButton2(
            <AppButton
              onClick={() =>
                actionData?.setPageMode(actionData?.PageModes.ASSIGN_PILOTS)
              }
              look={
                actionData?.checkInviteAssignDisablity()
                  ? "blackDisabled"
                  : "inverted"
              }
              label="Assign a single operator"
              isDisabled={actionData?.checkInviteAssignDisablity()}
              fullWidth
            />
          ),
          !projctMission &&
            setButton1(
              <AppButton
                onClick={() =>
                  actionData?.setPageMode(actionData?.PageModes.INVITE_PILOTS)
                }
                look={
                  actionData?.checkInviteAssignDisablity() ||
                  actionData?.mission.pilotInstructions === ""
                    ? "blackDisabled"
                    : "blue"
                }
                label="Invite drone operators"
                isDisabled={
                  actionData?.checkInviteAssignDisablity() ||
                  actionData?.mission.pilotInstructions === ""
                }
                fullWidth
              />
            ))
        : actionData?.mission?.pilotPackageStatus ===
          PilotPackageStatus.pendingReview
        ? setButton1(
            <AppButton
              onClick={checkData}
              look="blue"
              label="Verify the data"
              fullWidth
            />
          )
        : actionData?.mission?.status === missionStatus.completed &&
          actionData?.mission?.paymentStatusPilot === paymentStatus.unpaid
        ? setButton1(
            <AppButton
              onClick={goToInvoice}
              look="blue"
              label="Update status"
              fullWidth
            />
          )
        : adminActiveMissionButton()
      : isPilot &&
        (!actionData?.mission?.assignedPilot
          ? (!actionData?.declined &&
              setButton2(
                <AppButton
                  label="Reject mission"
                  look="inverted"
                  noIcon
                  onClick={() => actionData?.handlePilotDeclined()}
                  fullWidth
                />
              ),
            setButton1(
              <AppButton
                label={
                  actionData?.interestExists
                    ? "Change your price"
                    : "Show interest"
                }
                look={"purple"}
                fullWidth
                onClick={() => actionData?.openShowInterest()}
              />
            ))
          : actionData?.mission.status === missionStatus.active &&
            (actionData?.contract?.status === contractStatusLabel.signed ||
              !actionData.contractRequired)
          ? pilotActiveMissionButton()
          : // setButton1(
          //     <AppButton
          //       label="Upload your data"
          //       look="purple"
          //       fullWidth
          //       onClick={pilotUpload}
          //     />
          //   )
          actionData?.mission.status === missionStatus.active &&
            actionData?.contract?.status === contractStatusLabel.notSigned
          ? setButton1(
              <AppButton
                label="Sign contract"
                look="purple"
                fullWidth
                // onClick={pilotUpload}
                internalLink={true}
                url={`/app/my-profile/contracts/${actionData?.contract?.id}`}
              />
            )
          : actionData?.mission?.status === missionStatus.completed &&
            actionData?.mission?.paymentStatusPilot === paymentStatus.unpaid &&
            (actionData?.mission?.missionType ===
              missionTypeConstant.clientOrder ||
              actionData?.mission?.allowInvoice === true)
          ? (setButton2(
              <AppButton
                internalLink
                url="/app/my-profile/bank-details"
                look="inverted"
                label="Update your bank details"
                fullWidth
              />
            ),
            setButton1(
              <AppButton
                label="Approve your invoice"
                look="purple"
                fullWidth
                onClick={pilotSendInvoice}
              />
            ))
          : null);
  }, []);

  return (
    <Box>
      <UserNextActionsBanner
        actionText={actionText}
        actionSubText={actionSubText}
        actionSubText2={actionSubText2}
        background={background}
        button1={button1}
        button2={button2}
        bigText={bigText}
        redWarningText={
          <Fragment>
            {interestPilotCount?.length} other pilot have applied to this
            mission
          </Fragment>
        }
        redWarning={
          !actionData?.mission?.assignedPilot &&
          isPilot &&
          interestPilotCount?.length > 0
        }
      />
      <RejectModal
        showRejectDialog={showRejectDialog}
        setShowRejectDialog={setShowRejectDialog}
      />
    </Box>
  );
};

UserNextActions.propTypes = {
  actionText: PropTypes.string,
  actionSubText: PropTypes.string,
  actionSubText2: PropTypes.string,
  tabNumber: PropTypes.number,
  projctMission: PropTypes.bool,
  background: PropTypes.string,
  bigText: PropTypes.bool,
};

// UserNextActions.defaultProps = {
//   actionText: "Your next actions",
//   actionSubText: "",
//   actionSubText2: "",
//   tabNumber: undefined,
//   projctMission: false,
//   background: undefined,
//   bigText: false,
// };

export default UserNextActions;
