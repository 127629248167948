import firebase from "src/firebase";
import PropTypes from "prop-types";
import React from "react";
import useGetPilotList from "src/hooks/useGetPilotList";
import FilterPilotsForm from "./FilterPilotsForm";
import GlobalTable from "./GlobalTable";
import {
  baseFilters,
  columnForDroneOperators,
  columnForDroneOperatorsInviteCountry,
} from "../../../lib/constants";
import { navigate } from "gatsby";
import { Box } from "@mui/material";
import UniversalLoader from "../../global/elements/UniversalLoader";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { useCallback } from "react";
import AppButton from "../../global/elements/AppButton";
import {
  invitePilotToMission,
  uninvitePilotToMission,
} from "../../../services/missions";
import { createNotification } from "../../../services/notification";

const style = {
  loaderDiv: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  fontWeghtStyles: {
    fontWeight: "200 !important",
  },
};

const InvitePilotList = ({
  mission = {},
  assignPilotHandler = null,
  className = "",
  country = "",
  selected = [],
  setSelected,
  inviteFromCountry = false,
  assignPilot = false,
}) => {
  const [
    filteredPilotList,
    pilotsLoading,
    sortBy,
    currentPageNumber,
    isLastPilot,
    setSortBy,
    setCurrentPageNumber,
    setFilterorSortTriggered,
    setFilterBy,
    setLimit,
    filterBy,
    limit,
    allPilots,
  ] = useGetPilotList(
    ["dateCreated", "desc"],
    [{ ...baseFilters, Country: country }, true]
  );

  const [pilotList, setPilotList] = useState([]);
  const [allPilotList, setAllPilotList] = useState([]);
  const [localMissionData, setLocalMissionData] = useState([]);
  const [isSelectedAll, setSelectedAll] = useStateIfMounted(false);
  const [loadedPilots, setLoadedPilots] = useState(new Set());
  const [isInitialised, setIsInitialised] = useState(false);

  const isInvited = useCallback(
    (pilot) => {
      return (
        localMissionData?.sentToPilots?.some(
          (invitedPilotID) => invitedPilotID === pilot.id
        ) || false
      );
    },
    [localMissionData?.sentToPilots]
  );

  const hasDeclined = useCallback(
    (pilot) => {
      return (
        localMissionData?.declinedByPilots?.some(
          (declinedPilot) => declinedPilot.id === pilot.id
        ) || false
      );
    },
    [localMissionData?.declinedByPilots]
  );

  const initializeList = useCallback(
    (pilots) => {
      return pilots.map((pilot) => {
        let updatedPilot = Object.assign({}, pilot);
        updatedPilot.isInvited = isInvited(pilot);
        updatedPilot.hasDeclined = hasDeclined(pilot);
        updatedPilot.disabled = isInvited(pilot);
        return updatedPilot;
      });
    },
    [hasDeclined, isInvited]
  );

  useEffect(() => {
    if (filteredPilotList && filteredPilotList.length > 0) {
      setPilotList(initializeList(filteredPilotList));
    }
    if (allPilots && allPilots.length > 0) {
      setAllPilotList(initializeList(allPilots));
    }
    if (mission && !isInitialised) {
      setLocalMissionData(mission);
      setIsInitialised(true);
    }
  }, [filteredPilotList, initializeList, allPilots, mission, isInitialised]);

  useEffect(() => {
    if (filteredPilotList?.length) {
      const newPilotIds = new Set([...loadedPilots]);
      filteredPilotList.forEach((pilot) => newPilotIds.add(pilot.id));
      setLoadedPilots(newPilotIds);

      if (isSelectedAll) {
        const newPilots = initializeList(filteredPilotList).filter(
          (pilot) =>
            !selected.find((s) => s.id === pilot.id) && !pilot.isInvited
        );
        if (newPilots.length) {
          setSelected([...selected, ...newPilots]);
        }
      }
    }
  }, [filteredPilotList]);

  const sortByHandler = (sort) => {
    if (sort === sortBy[0]) {
      const order = sortBy[1] === "asc" ? "desc" : "asc";
      setSortBy([sort, order]);
      setFilterorSortTriggered(true);
    } else {
      setSortBy([sort, "asc"]);
      setFilterorSortTriggered(true);
    }
    setSelected([]);
  };

  const limitHandler = (value) => {
    setLimit(value);
    setFilterorSortTriggered(true);
  };

  const valueChangeHandler = (value, field, filterFields) => {
    let filterDataNew = [...filterBy];
    if (inviteFromCountry) {
      setSelected([]);
      setSelectedAll(false);
    }
    if (value === "" && field === "") {
      let changedField = Object.keys(filterDataNew[0]).filter(
        (fld) => filterFields.indexOf(fld) < 0
      );
      changedField.forEach((chgFld) => {
        if (
          chgFld === "Drone" ||
          chgFld === "Available Locations" ||
          chgFld === "Sensors/Cameras"
        ) {
          filterDataNew[0][chgFld] = [];
        } else {
          filterDataNew[0][chgFld] = "";
        }
      });
    } else if (value === "InitialLoad" && field === "InitialLoad") {
      if (!inviteFromCountry) {
        let filterDataSaved = JSON.parse(localStorage.getItem("filterData"));
        filterDataNew = filterDataSaved;
      }
    } else {
      filterDataNew[0][field] = value;
    }

    let filterData = [filterDataNew[0], true];

    if (!inviteFromCountry) {
      localStorage.setItem("filterData", JSON.stringify(filterData));
      localStorage.setItem("filterFieldsPilot", filterFields);
    }

    setFilterBy(filterData);
    setFilterorSortTriggered(true);
  };

  const invitePilotHandler = (pilotID) => {
    invitePilotToMission(firebase, pilotID, mission).then(() => {
      setLocalMissionData((prevData) => ({
        ...prevData,
        sentToPilots: [...(prevData?.sentToPilots || []), pilotID],
      }));
      setPilotList((prevList) =>
        prevList.map((pilot) =>
          pilot.id === pilotID
            ? { ...pilot, isInvited: true, disabled: true }
            : pilot
        )
      );
      setAllPilotList((prevList) =>
        prevList.map((pilot) =>
          pilot.id === pilotID
            ? { ...pilot, isInvited: true, disabled: true }
            : pilot
        )
      );
      createNotification(
        firebase,
        pilotID,
        `You are invited to a mission named ${mission.missionName}.`,
        "You are invited to a mission.",
        `/app/missions/${mission.id}`
      );
    });
  };

  const uninvitePilotHandler = (pilotID) => {
    uninvitePilotToMission(firebase, pilotID, mission).then(() => {
      setSelectedAll(false);
      setLocalMissionData((prevData) => ({
        ...prevData,
        sentToPilots: (prevData?.sentToPilots || []).filter(
          (id) => id !== pilotID
        ),
      }));
      setPilotList((prevList) =>
        prevList.map((pilot) =>
          pilot.id === pilotID
            ? { ...pilot, isInvited: false, disabled: false }
            : pilot
        )
      );
      setAllPilotList((prevList) =>
        prevList.map((pilot) =>
          pilot.id === pilotID
            ? { ...pilot, isInvited: false, disabled: false }
            : pilot
        )
      );
    });
  };

  const valueConvertHandler = (column, value) => {
    const isDisabled = !!selected.find((pilot) => pilot.id === value?.id);
    switch (column) {
      // case "date": {
      //   return (
      //     <Typography variant="h5" component="h6">
      //       {DateTime.fromISO(value["dateCreated"]).toISODate()}
      //     </Typography>
      //   );
      // }
      // case "array":
      //   return (
      //     <Typography variant="h5" component="h6">
      //       {value["equipment"] && value["equipment"].length > 0
      //         ? value["equipment"].toString()
      //         : "n/a"}
      //     </Typography>
      //   );
      case "rating":
        return (
          <Rating
            name={"overallRating"}
            value={value?.ratings?.overallRating || 0}
            precision={0.5}
            readOnly
          />
        );
      case "invite":
        return (
          <Box>
            {!value?.hasDeclined && !value?.isInvited && (
              <AppButton
                onClick={() => invitePilotHandler(value?.id)}
                look={isDisabled ? "blackDisabled" : "green"}
                isDisabled={isDisabled}
                label="Invite"
              />
            )}
            {!value?.hasDeclined && value?.isInvited && (
              <>
                <AppButton
                  onClick={() => uninvitePilotHandler(value?.id)}
                  look="negative"
                  label="Uninvite"
                />
              </>
            )}
          </Box>
        );
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    if (!inviteFromCountry) {
      navigate(`/app/pilots/${value.id}`);
    }
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPageNumber;
    if (type == "next") {
      if (!(isLastPilot.lastPageNumber === currentPageNumber)) {
        pageNumberNew = pageNumberNew + 1;
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
    }
    setCurrentPageNumber(pageNumberNew);
  };

  const handleSingleSelect = (event, row) => {
    if (event.target.checked) {
      setSelected([...selected, row]);
    } else {
      const newSelected = selected.filter(
        (selectedRow) => selectedRow.id !== row.id
      );
      setSelected(newSelected);
      if (isSelectedAll) {
        setSelectedAll(false);
      }
    }
  };

  const onSelectAllClick = (event) => {
    setSelectedAll(event.target.checked);
    if (event.target.checked) {
      const enabledPilots = initializeList(allPilotList)?.filter(
        (pilot) => !pilot.disabled
      );
      setSelected([...enabledPilots]);
    } else {
      setSelected([]);
    }
  };

  return pilotsLoading && localMissionData ? (
    <Box sx={{ ...style.loaderDiv }}>
      <UniversalLoader isFromInvite={inviteFromCountry} />
    </Box>
  ) : (
    <Box className={className}>
      <FilterPilotsForm
        valueChangeHandler={valueChangeHandler}
        country={country}
      />

      <GlobalTable
        columns={
          inviteFromCountry
            ? columnForDroneOperatorsInviteCountry
            : columnForDroneOperators
        }
        allData={inviteFromCountry ? pilotList : filteredPilotList}
        sortByHandler={sortByHandler}
        valueConvertHandler={valueConvertHandler}
        goToPageFcn={assignPilot ? assignPilotHandler : goToPageFcn}
        loading={pilotsLoading}
        limitHandler={limitHandler}
        limit={limit}
        inviteFromCountry={inviteFromCountry}
        checkbox={inviteFromCountry}
        selected={selected}
        onSelectAllClick={onSelectAllClick}
        completeDataLength={allPilots?.length}
        handleSingleSelect={handleSingleSelect}
        paginationBtnClick={paginationBtnClick}
        currentPage={currentPageNumber}
        isLastPage={isLastPilot.isLast}
        lastPage={isLastPilot.lastPageNumber}
        isSelectedAll={isSelectedAll}
      />
    </Box>
  );
};

InvitePilotList.propTypes = {
  mission: PropTypes.object,
  assignPilotHandler: PropTypes.func,
  className: PropTypes.string,
  assignPilot: PropTypes.bool,
  inviteFromCountry: PropTypes.bool,
  country: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default InvitePilotList;
