import { AppBar, Box } from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import ProjectHeaders from "../Components/ProjectHeaders";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import { useGetProjectInfo } from "../../../../hooks/useGetProjectInfo";
import Layout from "../../../../Layout";
import {
  downloadProjectMissionColumn,
  missionStatus,
  orderCollectionData,
  projectStatus,
  userRoles,
} from "../../../../lib/constants";
import { StyledTab, StyledTabs, appBar } from "../../../../lib/styleConstants";
import { getUser } from "../../../../services/auth";
import { getClientById, getEnterPrise } from "../../../../services/client";
import theme from "../../../../theme";
import TabPanel from "../../../global/elements/TabPanel";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import AdminCommunication from "../../../global/TwoWayCommunication/MultiLocationOrderCommunication/AdminCommunication";
import ClientCommunication from "../../../global/TwoWayCommunication/MultiLocationOrderCommunication/ClientCommunication";
import DroneOperatorCommunication from "../../../global/TwoWayCommunication/MultiLocationOrderCommunication/DroneOperatorCommunication";
import DownloadProjectDataTable from "./downloadProjectDataTable/DownloadProjectDataTable";
import ProjectDetailsTab from "./ProjectDetailsTab/ProjectDetailsTab";
import QuotesTab from "./QuotesTab/QuotesTab";
import FeedbackOrder from "../../missions/FeedbackTab/FeedbackOrder";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = {
  mainTabContainer: {
    width: "100%",
    marginTop: "40px",
    background: theme.palette.grey.white,
  },
  universalLoaderPosition: {
    marginTop: { xs: "50%", sm: "40%", md: "30%", lg: "20%" },
  },
  tabPanel: {
    padding: 0,
  },
};
const myAssignedMission = "MY ASSIGNED SITES";
const missions = "MISSIONS";

const ProjectMainLayout = ({ projectId = "" }) => {
  const currentUser = getUser("user");
  const pilotId =
    currentUser?.role == userRoles.pilot ? currentUser.userID : null;
  const [
    projectDetails,
    projectMissions,
    allPilotsData,
    allAdminsData,
    barchartData,
    loading,
    triggerReload,
    setTriggerReload,
  ] = useGetProjectInfo({ projectId, role: currentUser?.role, pilotId });
  // here sectionActive === 0 is admin and sectionActive === 1 is pilot

  const handleTabChange = (e, newTabValue) => {
    setTabValue(newTabValue);
  };
  const [sectionActive, setSectionActive] = useState(0);
  const [changeRole, setChangeRole] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [currency, setCurrency] = useState(null);

  // Optimize role change effect
  useEffect(() => {
    setChangeRole(sectionActive === 1);
  }, [sectionActive]);

  // Optimize tab value initialization
  useEffect(() => {
    const initializeTabValue = () => {
      if (window.location.hash === "#communications") {
        return currentUser?.role === userRoles.pilot ? 1 : 2;
      }
      return 0;
    };

    setTabValue(initializeTabValue());
  }, [currentUser?.role, projectDetails]);

  // Memoize currency fetching to prevent unnecessary re-renders
  const getCurrency = useCallback(async (project) => {
    try {
      if (!project || !project?.id) {
        return null;
      }

      let fetchedCurrency = null;

      if (project?.enterPriseId) {
        const enterprise = await getEnterPrise(project?.enterPriseId);
        fetchedCurrency = enterprise?.currency;
      } else if (project?.clientId) {
        const client = await getClientById(firebase, project.clientId);
        fetchedCurrency = client?.currency;
      }

      setCurrency((prevCurrency) => fetchedCurrency ?? prevCurrency);
      return fetchedCurrency;
    } catch (e) {
      console.error("Error fetching currency", e);
      return null;
    }
  }, []);

  const showDownloadTab =
    currentUser?.role !== userRoles.pilot &&
    projectDetails?.status === projectStatus.completed;

  // Separate effect for currency fetching
  useEffect(() => {
    if (currentUser.role !== userRoles.pilot && projectDetails?.id) {
      getCurrency(projectDetails);
    }
  }, [currentUser.role, getCurrency, projectDetails]);
  useEffect(() => {
    console.log("Currency updated to:", currency);
  }, [currency]);

  const refreshProject = () => {
    setTriggerReload(!triggerReload);
  };

  return (
    <>
      {!loading ? (
        <Layout>
          <ProjectHeaders
            role={currentUser?.role}
            currentProjectStatus={projectDetails?.status}
            projectId={projectId}
            projectName={projectDetails?.projectName}
            completedMissionCount={projectDetails?.allCompletedMissionCount}
            allMissionCount={projectDetails?.allMissionCount}
            sectionActive={sectionActive}
            setSectionActive={setSectionActive}
            projectMissions={projectMissions}
          />

          <Box sx={styles.mainTabContainer}>
            <AppBar position="static" sx={appBar} elevation={0}>
              <StyledTabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <StyledTab
                  key="project details"
                  label="project Details"
                  {...a11yProps(0)}
                />
                {currentUser?.role !== userRoles.pilot && (
                  <StyledTab
                    key="quotes & invoices"
                    label="quotes & invoices"
                    {...a11yProps(1)}
                  />
                )}
                <StyledTab
                  key="communication"
                  label="Communication"
                  {...a11yProps(currentUser?.role === userRoles.pilot ? 1 : 2)}
                />

                {showDownloadTab && (
                  <StyledTab
                    key="downloadProject"
                    label="Download Project"
                    {...a11yProps(3)}
                  />
                )}
                {projectDetails?.status === projectStatus.completed &&
                  currentUser?.role !== userRoles.pilot && (
                    <StyledTab
                      key="feedback"
                      label="Client Feedback"
                      {...a11yProps(4)}
                    />
                  )}
              </StyledTabs>
            </AppBar>
            <TabPanel value={tabValue} index={0} key="projectDetails">
              <ProjectDetailsTab
                currentUser={currentUser}
                changeRole={changeRole}
                projectDetails={projectDetails}
                projectMissions={projectMissions}
                allPilotsData={allPilotsData}
                allAdminsData={allAdminsData}
                barchartData={barchartData}
                triggerReload={triggerReload}
                setTriggerReload={setTriggerReload}
              />
            </TabPanel>
            {currentUser?.role !== userRoles.pilot && (
              <TabPanel value={tabValue} index={1} key="quotesDetails">
                <QuotesTab
                  projectId={projectId}
                  project={projectDetails}
                  role={changeRole ? userRoles.client : currentUser?.role}
                  triggerReload={triggerReload}
                  setTriggerReload={setTriggerReload}
                  currency={currency}
                />
              </TabPanel>
            )}
            <TabPanel
              value={tabValue}
              index={currentUser?.role === userRoles.pilot ? 1 : 2}
              key="communication"
            >
              {currentUser.role === userRoles.pilot ? (
                <DroneOperatorCommunication projectDetails={projectDetails} />
              ) : currentUser.role === userRoles.admin ? (
                <AdminCommunication projectDetails={projectDetails} />
              ) : (
                <ClientCommunication projectDetails={projectDetails} />
              )}
            </TabPanel>

            {showDownloadTab && (
              <TabPanel value={tabValue} key="downloadProject" index={3}>
                <DownloadProjectDataTable
                  title={
                    currentUser?.role === userRoles.pilot
                      ? myAssignedMission
                      : missions
                  }
                  columns={downloadProjectMissionColumn}
                  allData={projectMissions}
                  noDataAvailableText={
                    "There are no missions in this project currently."
                  }
                  project={{
                    projectId: projectDetails?.id,
                    projectName: projectDetails?.projectName,
                  }}
                />
              </TabPanel>
            )}
            {projectDetails?.status === projectStatus.completed &&
              currentUser?.role !== userRoles.pilot && (
                <TabPanel value={tabValue} index={4} key="feedback">
                  <Box m={2}>
                    <FeedbackOrder
                      data={projectDetails}
                      type={orderCollectionData.projects}
                      refreshPage={refreshProject}
                    />
                  </Box>
                </TabPanel>
              )}
          </Box>
        </Layout>
      ) : (
        <Box sx={styles.universalLoaderPosition}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
};

ProjectMainLayout.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ProjectMainLayout;
