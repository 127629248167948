import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../theme";
import { fontWeightFive } from "../../../lib/styleConstants";
import InfoBox from "../../global/elements/InfoBox";
import EditRoadSharpIcon from "@mui/icons-material/EditRoadSharp";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import CheckIcon from "@mui/icons-material/Check";
import SdgImg from "../../global/elements/SdgImg";
import AppButton from "../../global/elements/AppButton";
import { FileDownloadOutlined } from "@mui/icons-material";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import MapDrawingTool from "../../../components/app/mapDrawingTool/MapDrawingTool";
import {
  OrderTypeConstant,
  areaUnits,
  deliveryDataType,
  dataLibBaseFilesFileType,
  dataLibraryZoneUnitPrice,
  userRoles,
  users,
} from "../../../lib/constants";
import {
  getDeliveryPackageByMissionId,
  getMission,
} from "../../../services/missions";
import firebase from "src/firebase";
import { getDeliveryFilesFromFileDataType } from "../../../services/delivery-files";
import {
  convertFileSizeUnit,
  getPriceAccordingToZone,
  getZoneFromLocation,
  displayAreaSizeInSelectedUnits,
  areaSizeUnitNotation,
  getFileSizeInMb,
} from "../../../lib/helpers";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../services/auth";
import { getCartDetail } from "../../../redux/actions/cart-detail-action";
import { useSnackbar } from "notistack";
import GuestStatusAlert from "../AnonymousUser/GuestStatusAlert";
import Tips from "../../global/elements/Tips";
import CharacterDropDown from "../../global/elements/CharacterDropDown";
import { changeMillisToDate } from "../../../lib/helpers";
import { DateTime } from "luxon";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

const styles = {
  gridContainer: {
    marginTop: "20px",
    flexDirection: { xs: "column-reverse", md: "initial" },
  },
  infoGridItem: { marginTop: { xs: "50px", sm: 0 } },
  mapGridItem: {},
  headingColor: { color: theme.palette.black.darkSeaBlack },
  subTextColor: { color: theme.palette.grey.warmGrey },
  displayProp: { display: "flex" },
  mTop: { marginTop: "20px" },
  marginTopCls: { marginTop: "40px" },
  topDivider: {
    borderColor: theme.palette.black.darkSeaBlack,
    margin: "10px 0px",
    borderWidth: "1px",
  },
  costHeading: {
    color: theme.palette.black.coolGrey,
  },
  checkIcon: {
    fontSize: { xs: "18px", md: "22px", lg: "25px" },
    color: theme.palette.secondary.activeGreen,
    fontWeight: 500,
  },
  mapTypes: {
    paddingTop: "2%",
  },
  categoriesStyle: {
    display: "inline-grid",
    width: { xs: "100%", sm: "50%" },
  },
  displayStyle: { display: "flex" },
  mapLabelText: {
    paddingLeft: "1%",
  },
  btnStyle: {
    width: "100%",
    justifyContent: "center",
  },
  toolButton: {
    width: "156px",
    height: "50px",
    textTransform: "none",
    borderRadius: 0,
  },
  notAllow: {
    color: theme.palette.status.alert,
    textAlign: "center",
    margin: "auto",
    marginTop: "17%",
  },
  backBtn: {
    textAlign: "center",
    margin: "0 auto",
  },
};

const SingleOrderDataLibrary = ({ missionId }) => {
  const [missionData, setMissionData] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(areaUnits?.sqkm);
  const [fileSizeString, setFileSizeString] = useState(0);
  // const [deliveryFiles, setDeliveryFiles] = useState(null);
  const [sampleDeliveryFile, setSampleDeliveryFile] = useState("");
  // const [filesSize, setFilesSize] = useState(null);
  const [zoneUnitPrice, setZoneUnitPrice] = useState();
  const [zone, setZone] = useState(4);
  const [showPage, setShowPage] = useState(true);
  const [showBtn, setShowBtn] = useState(true);
  const [userData, setUserData] = useState({});
  const [role, setRole] = useState(null);
  const [showDownloadData, setShowDownloadData] = useState(false);
  const [showSampleData, setShowSampleData] = useState(true);
  const [abortController] = useState(new AbortController());
  const [loading, setLoading] = useState(true);
  const [fileSizeMb, setFileSizeMb] = useState(0);
  const [fileSizeByte, setFileSizeByte] = useState(0);
  const [inCartMission, setInCartMission] = useState(false);

  const dispatch = useDispatch();
  const { CartReducer } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  const dataLibraryTitle = missionData?.dataLibrary?.missionTitle;
  const missionName = missionData?.missionName;

  useEffect(() => {
    if (!firebase) return;
    setLoading(true);
    const userInfo = getUser(users.userProfile);
    const user = getUser(users.user);
    if (user?.isAnonymous) {
      setUserData(user);
      setRole(user.role);
      return;
    } else {
      if (userInfo && userInfo.email) {
        setUserData(userInfo);
        setRole(userInfo.role);
      }
    }
  }, []);

  useEffect(() => {
    if (!firebase) return;
    async function fetchData() {
      setLoading(true);
      if (missionId && userData && userData.role) {
        let missionDataStatus = await fetchMissionDetails();
        if (missionDataStatus.status === "Success") {
          if (userData.role != userRoles.pilot) {
            const deliveryDataStatus = await getDeliveryData();
            if (deliveryDataStatus.status === "Success") {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        }
      }
    }
    fetchData();

    return function cancel() {
      abortController.abort();
    };
  }, [userData]);

  const getDeliveryData = () => {
    return getDeliveryPackageByMissionId(missionId, firebase)
      .then((packageId) => {
        if (packageId) {
          return getDeliveryFilesFromFileDataType({
            lastVisible: null,
            missionId: missionId,
            deliveryPackageID: packageId,
            fileDataType: deliveryDataType.baseFiles,
            limit: 1,
            fileType: dataLibBaseFilesFileType,
          })
            .then(async (imgData) => {
              if (imgData) {
                if (
                  imgData?.files[0] &&
                  imgData?.files[0]?.fileType &&
                  imgData?.files[0]?.fileType.startsWith("image/")
                ) {
                  // setDeliveryFiles(imgData?.files[0]);
                  if (imgData?.files[0]?.url) {
                    setSampleDeliveryFile(imgData?.files[0]?.url);
                  }
                }

                const getPackageFileCount = await firebase
                  .functions()
                  .httpsCallable("getPackageFileCount");

                const basicInfo = await getPackageFileCount({
                  packageType: "deliveryPackages",
                  packageFileType: "deliveryFiles",
                  missionId: missionId,
                  packageId: packageId,
                  fileDataType: null,
                  getAll: true,
                });

                // let sizeString = convertFileSizeUnit(
                //   basicInfo?.data?.packageInfo?.packageSize || 0
                // );
                // let size = 0;
                // setFileSizeString(sizeString);
                // if (sizeString) {
                //   size = sizeString.slice(0, -2);
                // }
                // setFilesSize(Number(size));
                const deliveryFileSize =
                  basicInfo?.data?.packageInfo?.packageSize || 0;

                let sizeString = convertFileSizeUnit(deliveryFileSize);

                setFileSizeString(sizeString);
                let sizeInMb = getFileSizeInMb(deliveryFileSize);
                setFileSizeMb(sizeInMb);
                setFileSizeByte(deliveryFileSize);
                return {
                  status: "Success",
                };
              }
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
              return {
                status: "Error",
              };
            });
        } else {
          return {
            status: "Success",
          };
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        return {
          status: "Error",
        };
      });
  };

  const fetchMissionDetails = () => {
    return getMission(firebase, missionId)
      .then((data) => {
        if (!data.notShowInDataLibrary) {
          setShowPage(true);
          setMissionData(data);
          let zoneNum = getZoneFromLocation({
            mission: data,
          });
          setZone(zoneNum);
          setZoneUnitPrice(dataLibraryZoneUnitPrice[zoneNum]);

          const foundInCart = CartReducer.carts.some((el) => el.id === data.id);

          // Free Download Case
          if (data.freeToDownload) {
            setShowBtn(false);
            setShowDownloadData(true);
            setShowSampleData(false);
          } else if (role == userRoles.pilot) {
            setShowSampleData(true);
            setShowDownloadData(false);
            setShowBtn(false);
          } else if (role == userRoles.client) {
            if (
              // userData.id === data?.clientId ||
              // data?.dataPurchasedBy?.includes(userData?.id) ||
              // userData?.enterPriseId === data?.enterPriseId ||
              // data?.dataPurchasedByEnterPrise?.includes(userData?.enterPriseId)
              checkMissionOwner(data) ||
              checkMissionPurchased(data)
            ) {
              setShowBtn(false);
              setShowDownloadData(true);
              setShowSampleData(false);
            } else if (foundInCart) {
              setInCartMission(true);
              setShowBtn(false);
              setShowDownloadData(false);
              setShowSampleData(true);
            } else if (data.freeToDownload) {
              setShowBtn(false);
              setShowDownloadData(true);
              setShowSampleData(false);
            } else {
              setShowBtn(true);
              setShowDownloadData(false);
              setShowSampleData(true);
              setInCartMission(false);
            }
          }
        } else {
          setShowPage(false);
        }
        return {
          status: "Success",
        };
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
        return {
          status: "Error",
        };
      });
  };

  const addToCart = () => {
    let price = getPriceAccordingToZone({ fileSize: fileSizeMb, zone });
    let flag = CartReducer.carts.filter((data) => data?.id === missionData.id);
    if (flag?.length > 0) {
      enqueueSnackbar("This mission is already in your cart", {
        variant: "error",
      });
    } else {
      dispatch(
        getCartDetail({
          ...missionData,
          orderPrice: price,
          // filesSize: filesSize,
          fileSizeString: fileSizeString,
          fileSizeByte: fileSizeByte,
          fileSizeMb: fileSizeMb,
        })
      );
      enqueueSnackbar("Your selected mission is added in your cart", {
        variant: "success",
      });
    }
  };

  const checkMissionOwner = (missionData) => {
    if (userData?.id === missionData?.clientId) {
      return true;
    } else if (
      userData?.enterPriseId &&
      userData.enterPriseId == missionData?.enterPriseId
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkMissionPurchased = (missionData) => {
    if (missionData?.dataPurchasedBy?.includes(userData?.id)) {
      return true;
    } else if (
      userData?.enterPriseId &&
      missionData?.dataPurchasedByEnterPrise?.includes(userData.enterPriseId)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!loading ? (
        <>
          {showPage ? (
            <>
              <Grid container>
                {userData?.isAnonymous && !missionData?.freeToDownload && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={styles.infoGridItem}
                  >
                    <GuestStatusAlert />
                  </Grid>
                )}
                {checkMissionPurchased(missionData) && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={styles.infoGridItem}
                  >
                    <Tips
                      content={`This data is already purchased`}
                      subContent={`You have already purchased this data and 
                      you can't move it to cart again, But yes you can download it again!`}
                    />
                  </Grid>
                )}

                {checkMissionOwner(missionData) && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={styles.infoGridItem}
                  >
                    <Tips
                      content={`This data order set belongs to you`}
                      subContent={`You can find this mission 
                                     in your completed order section`}
                    />
                  </Grid>
                )}

                {inCartMission && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={styles.infoGridItem}
                  >
                    <Tips content={`This mission is already in your cart`} />
                  </Grid>
                )}
              </Grid>

              <Grid container columnSpacing={2} sx={styles.gridContainer}>
                <Grid item xs={12} sm={12} md={5} sx={styles.infoGridItem}>
                  <Typography variant="h4" sx={styles.headingColor}>
                    {dataLibraryTitle ? dataLibraryTitle : missionName}
                  </Typography>

                  <Typography variant="subtitle1Med" sx={styles.subTextColor}>
                    {missionData?.locationLabel}
                  </Typography>

                  <Grid container mt={2} columnSpacing={3}>
                    <Grid item md={6} sx={styles.displayProp}>
                      <Typography variant="body1" sx={fontWeightFive}>
                        ID
                      </Typography>
                      <Typography variant="body1" ml={1}>
                        {missionData?.id}
                      </Typography>
                    </Grid>

                    <Grid item md={6} sx={styles.displayProp}>
                      <Typography variant="body1" sx={fontWeightFive}>
                        Date available
                      </Typography>
                      <Typography variant="body1" ml={1}>
                        {missionData?.dateCompleted
                          ? changeMillisToDate(missionData?.dateCompleted)
                          : DateTime.fromISO(
                              missionData?.dateCreated
                            ).toISODate()}{" "}
                      </Typography>
                    </Grid>
                  </Grid>

                  {missionData?.dataLibrary?.description && (
                    <InfoBox
                      title="Information"
                      subText={missionData?.dataLibrary?.description}
                      additionalStyle={styles.mTop}
                    />
                  )}

                  {missionData?.orderType != OrderTypeConstant.assets &&
                    missionData?.areaSize && (
                      <Grid container sx={styles.marginTopCls}>
                        <Grid item xs={1}>
                          <EditRoadSharpIcon fontSize="medium" />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            {displayAreaSizeInSelectedUnits(
                              Number(Number(missionData?.areaSize)?.toFixed(2)),
                              missionData?.orderType || "area",
                              selectedUnit
                            ) || "Surface"}
                            {areaSizeUnitNotation(
                              missionData?.orderType || "area",
                              selectedUnit
                            ) || "km2"}
                          </Typography>
                        </Grid>{" "}
                        <Grid item xs={8}>
                          <CharacterDropDown
                            orderType={missionData?.orderType}
                            selectedUnit={selectedUnit}
                            setSelectedUnit={setSelectedUnit}
                          />
                        </Grid>
                      </Grid>
                    )}

                  {userData?.role != userRoles.pilot && (
                    <Box sx={{ ...styles.displayProp, ...styles.mTop }}>
                      <AnalyticsOutlinedIcon fontSize="medium" />
                      <Typography variant="subtitle1" ml={1}>
                        {fileSizeString ? fileSizeString : "0"}
                      </Typography>
                    </Box>
                  )}

                  <Divider variant="fullWidth" sx={styles.topDivider} />

                  {userData?.role != userRoles.pilot && (
                    <Box mt={2}>
                      <Typography variant="h6" sx={styles.costHeading}>
                        Total cost
                      </Typography>
                      <Typography variant="h2" mt={1}>
                        {missionData?.freeToDownload
                          ? "Free"
                          : "$" + (zoneUnitPrice * fileSizeMb).toFixed(2)}
                      </Typography>
                      {!missionData?.freeToDownload && (
                        <Typography
                          variant="subtitle1"
                          sx={styles.costHeading}
                          mt={1}
                        >
                          $ {zoneUnitPrice} /MB unit price
                        </Typography>
                      )}
                    </Box>
                  )}

                  <Box sx={styles.productTypes} mt={2}>
                    <Typography
                      sx={{ ...styles.dataTitle, ...fontWeightFive }}
                      variant="body2"
                    >
                      Product types includes in this dataset:
                    </Typography>
                    <Box>
                      {missionData?.mapTypes?.map((mapLabel, i) => {
                        return (
                          <Box key={i} sx={styles.mapTypes}>
                            <Box sx={styles.displayStyle}>
                              <CheckIcon sx={styles.checkIcon} />
                              <Typography
                                sx={styles.mapLabelText}
                                variant="body2"
                              >
                                {mapLabel}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>

                  {missionData?.dataLibrary?.categories?.length > 0 && (
                    <Box sx={styles.missionCategories} mt={2}>
                      <Typography
                        sx={{ ...styles.dataTitle, ...fontWeightFive }}
                        variant="body2"
                      >
                        Categories :
                      </Typography>
                      <Box mt={2}>
                        {missionData?.dataLibrary?.categories.map(
                          (category, i) => {
                            return (
                              <Box key={i} sx={styles.categoriesStyle}>
                                <Box sx={styles.displayStyle}>
                                  <CheckIcon sx={styles.checkIcon} />
                                  <Typography
                                    sx={styles.catLabel}
                                    variant="body2"
                                  >
                                    {category.label}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  )}

                  {missionData?.dataLibrary?.sdgGoals?.length > 0 && (
                    <Box mt={2}>
                      <Box>
                        <Typography
                          sx={{ ...styles.dataTitle, ...fontWeightFive }}
                          variant="body2"
                        >
                          SDG Goals :
                        </Typography>
                        <SdgImg sdgGoals={missionData?.dataLibrary?.sdgGoals} />
                      </Box>
                    </Box>
                  )}

                  <Box>
                    {showSampleData && (
                      <AppButton
                        url={sampleDeliveryFile}
                        externalLink
                        targetNewWidow
                        look={"inverted"}
                        label={"Download a sample image"}
                        customIcon={<FileDownloadOutlined />}
                        downloadFunction
                        addtionalStyle={{
                          ...styles.btnStyle,
                          ...styles.mTop,
                        }}
                      />
                    )}
                  </Box>

                  {!userData?.isAnonymous &&
                    !missionData?.freeToDownload &&
                    showBtn &&
                    userData?.role != userRoles.pilot && (
                      <Box mt={2}>
                        <AppButton
                          look={"green"}
                          label={"Add to cart"}
                          customIcon={<AddShoppingCartOutlinedIcon />}
                          addtionalStyle={styles.btnStyle}
                          onClick={() => addToCart()}
                        />
                      </Box>
                    )}
                  {showDownloadData && (
                    <AppButton
                      label="Download data"
                      customIcon={<FileDownloadOutlined />}
                      addtionalStyle={{ ...styles.btnStyle, ...styles.mTop }}
                      downloadFunction
                      look="green"
                      onClick={() => {
                        navigate(
                          `/app/client/datalibrary/download/${missionId}`
                        );
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={7} sx={styles.mapGridItem}>
                  <MapDrawingTool
                    area={missionData?.locationMap}
                    readonly
                    mapHeight={{
                      height: "650px",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    type={missionData?.orderType}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            // </ArrowHeaderContainer>
            <Box sx={styles.notAllow}>
              <Box>You are not allowed to visit this page</Box>
              <Box>
                <AppButton
                  look={"black"}
                  label="Back"
                  link={"/app/client/data-library"}
                  addtionalStyle={styles.backBtn}
                />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ marginTop: "17%" }}>
          <UniversalLoader />
        </Box>
      )}
    </>
  );
};

SingleOrderDataLibrary.propTypes = {
  missionId: PropTypes.string.isRequired,
};

export default SingleOrderDataLibrary;
