import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { createFeedback } from "../../services/feedback";
import useGetPilot from "src/hooks/useGetPilot";
import { getUser } from "../../services/auth";
import FeedbackPopupTheme from "../app/missions/FeedbackTab/FeedbackPopupTheme";

const CustomizedDialogs = ({ handleClose, popupOpen }) => {
  // const [rating, setRating] = useState(3);
  // const [typeValue, settypeValue] = useState("Comments");
  // const [description, setDescription] = useState("");
  const [pilot] = useGetPilot(getUser("user").userID);
  const [success, setSuccess] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const handleSubmit = async (values) => {
    setshowLoader(true);
    const feed = {
      rating: values.rating,
      FeedbackType: values.typeValue,
      description: values.description,
      email: pilot?.email,
      name: pilot?.name,
      company: pilot?.company ? pilot?.company : null,
      phone: pilot?.phone ? pilot?.phone : null,
      id: pilot?.id,
      feedbackBy: "pilot",
    };
    const data = await createFeedback(feed);
    if (data) {
      setshowLoader(false);
      setSuccess(true);
      setTimeout(() => {
        handleClose();
        setSuccess(false);
      }, 2000);
    }
  };
  const closeFcn = () => {
    handleClose();
  };
  return (
    <Box>
      <FeedbackPopupTheme
        closeFcn={closeFcn}
        popupOpen={popupOpen}
        success={success}
        user={pilot}
        loading={showLoader}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

CustomizedDialogs.propTypes = {
  popupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CustomizedDialogs;
