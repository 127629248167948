import React from "react";
import ProfileImage from "../../../global/ProfileImage";
import { Grid, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import { termAcceptStatus } from "../../../../lib/constants";
import { userProfileImage } from "../../../../lib/styleConstants";
import Rating from "@mui/material/Rating";
import RatingRemarks from "src/components/forms/rating/RatingRemarks";
import { getUser } from "src/services/auth";
import { userRoles } from "../../../../lib/constants";

const styles = {
  mT: { marginTop: "20px" },
  subscriptionSec: { marginTop: { lg: "20px" } },
  badge: {
    margin: { xs: "20px 0 0 0", sm: "5px 0 15px 0", lg: "auto" },
  },
  mR: {
    marginRight: { xs: "10px", sm: "15px" },
    width: "40px",
  },
  progressBar: {
    width: "50px",
    height: "50px",
  },
  mB: {
    margin: "auto",
    marginBottom: "0px",
  },
  redText: {
    textAlign: "right",
    marginTop: "20px",
    color: theme.palette.status.errorDark,
    border: `1px solid ${theme.palette.status.errorDark}`,
    width: "fit-content",
    float: "right",
    borderRadius: "2px",
    padding: "2px",
  },
  greenText: {
    textAlign: "right",
    marginTop: "20px",
    color: theme.palette.status.successDark,
    border: `1px solid ${theme.palette.status.successDark}`,
    width: "fit-content",
    float: "right",
    borderRadius: "2px",
    padding: "2px 4px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
};

const ClientInfoHeader = ({ profile, showClientStatus, isPilot }) => {
  const isAdmin = getUser("user").role === userRoles.admin;

  // ----uncomment commented code in case of subscription is enabled---

  // const [planType, setPlanType] = useState(null);

  // const getUsedLimit = () => {
  //   let remainingLimit = 0;
  //   let leftDataPercent = 0;
  //   remainingLimit = getRemainingQuotaForSubscription(
  //     profile?.subTerm,
  //     profile?.totalSubLimit,
  //     profile?.dateCreated,
  //     profile?.usedSubLimit,
  //     profile?.missionList
  //   );
  //   if (remainingLimit > 0) {
  //     leftDataPercent =
  //       (Number(remainingLimit) / Number(profile?.totalSubLimit)) * 100;
  //   }

  //   return Math.round(leftDataPercent);
  // };

  // useEffect(() => {
  //   currentPlanStatus();
  // }, [clientId]);

  // const currentPlanStatus = async () => {
  //   try {
  //     const currentStatus = await getSubDetails(firebase, clientId);
  //     if (currentStatus?.status == noSubscriptionAvailable.noSubscription) {
  //       setPlanType(noSubscriptionAvailable.noSubscription);
  //     } else if (currentStatus?.status == "Success") {
  //       if (
  //         currentStatus &&
  //         currentStatus?.data[0] &&
  //         currentStatus?.data[0]?.subType
  //       ) {
  //         setPlanType(currentStatus.data[0].subType);
  //       }
  //     }
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // };

  return (
    <Grid container alignItems={"center"}>
      <Grid container width={"85%"} alignItems={"center"}>
        <Grid item xs={4} sm={2.25} lg={2}>
          <ProfileImage
            imageURL={profile?.profileImageURL}
            style={userProfileImage}
          />
        </Grid>
        <Grid item xs={8} sm={4.65} lg={5}>
          <Typography variant="h4">{profile?.name}</Typography>
          {isPilot ? (
            <Box sx={styles.flex}>
              <Typography variant="body2">{profile?.city},&nbsp;</Typography>
              <Typography variant="body2">{profile?.location}</Typography>
            </Box>
          ) : (
            <Typography variant="body2">{profile?.jobTitle}</Typography>
          )}
        </Grid>
      </Grid>
      {isAdmin && (
        <Grid
          width={"15%"}
          container
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Grid item>
            <Rating
              name={"overallRating"}
              value={profile?.ratings?.overallRating || 0}
              precision={0.5}
              readOnly
            />
          </Grid>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Typography variant="body3">Overall Rating:&nbsp;</Typography>
            <RatingRemarks
              ratingField={"overallRating"}
              rating={profile?.ratings?.overallRating || 0}
            />
          </Grid>
        </Grid>
      )}
      {showClientStatus && (
        <Grid xs={12} sm={5} lg={5}>
          <Typography
            sx={profile?.termsOfUse ? styles.greenText : styles.redText}
            variant="body2"
          >
            {profile?.termsOfUse
              ? termAcceptStatus.registered
              : termAcceptStatus.locked}
          </Typography>
        </Grid>
      )}
      {/* <Grid item xs={12} sm={5} lg={5.5} sx={styles.subscriptionSec}>
        <Grid container xs={12}>
          <Grid item xs={3.5} sm={12} lg={5} xl={5} sx={styles.badge}>
            <StatusBadge
              name={planType}
              status={
                planType == noSubscriptionAvailable.noSubscription
                  ? colorStatus.neutral
                  : colorStatus.green
              }
            />
          </Grid>
          <Grid sx={styles.mR}>
            <CircularProgressBar
              leftQuota={getUsedLimit()}
              reversed={true}
              smallerText={true}
              additionalStyle={styles.progressBar}
              strokeWidth={20}
              percentTextNeed={false}
            />
          </Grid>
          <Grid item xs={6} sm={9} lg={5} xl={5.5} sx={styles.mB}>
            <Typography variant="h4" component={"span"}>
              {getUsedLimit() || "100"}% &nbsp;
            </Typography>
            <Typography variant="body2" component={"span"}>
              {"left of quote"}
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

ClientInfoHeader.propTypes = {
  profile: PropTypes.object.isRequired,
  showClientStatus: PropTypes.bool,
  isPilot: PropTypes.bool,
};

export default ClientInfoHeader;
