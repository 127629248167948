import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import GridColumn from "src/components/global/GridColumn";
import GridContainer from "src/components/global/GridContainer";
import {
  dailyReportStatus,
  uncompleteReasonsOptions,
  reportType,
} from "../../../lib/constants";
import { Typography } from "@mui/material";
import firebase from "src/firebase";
import ErrorMessage from "src/components/global/errorMessage";
import useGetDailyReport from "../../../hooks/useGetDailyReport";
import { getDailyReportStatus } from "../../../services/handle-operations";
import { updateDailyReportStatus } from "../../../services/handle-operations";
import { DateTime } from "luxon";
import theme from "../../../theme";
import Layout from "../../../Layout";
import { Box } from "@mui/material";
import AppButton from "../../global/elements/AppButton";

const styles = {
  "@global body": {
    background: theme.palette.grey.lightWarmGrey,
  },
  root: {
    "& h1": {
      fontSize: "1.5rem",
      textAlign: "center",
    },
  },
  mainInfo: {
    textAlign: "center",
    position: "relative",
    paddingBottom: "100px !important",
    paddingTop: "80px !important",
    background: theme.palette.grey.white,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    marginTop: "2rem",
    height: "250px",
    "& li": {
      width: "100%",
      padding: "0.25rem 0",
      lineHeight: "1.5em",
    },
  },
  leftColumn: {
    display: "block",
  },
  dataColumn: {
    padding: "2rem",
    background: theme.palette.grey.white,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    marginTop: "2rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& li": {
      width: "100%",
      padding: "0.25rem 0",
      lineHeight: "1.5em",
    },
  },
  pilotProfile: {
    marginBottom: "2rem",
    marginTop: "3rem !important",
  },
  pilotInfoUl: {
    padding: 0,
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    background: theme.palette.grey.white,
    margin: 0,
    overflowWrap: "break-word",
  },
  plabel: {
    float: "left",
    fontWeight: 500,
    width: "40%",
  },
  statusColumn: {
    padding: "2rem 0.3rem",
    background: theme.palette.grey.white,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    marginTop: "2rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    height: "250px",
  },
  data: {
    float: "right",
    width: "60%",
    paddingLeft: "1rem",
  },
  requested: {
    color: theme.palette.primary.oldMain,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: "2rem",
    },
  },
  read: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  unread: {
    color: theme.palette.status.errorDark,
    textTransform: "capitalize",
  },
  revokeButton: {
    cursor: "pointer",
    color: theme.palette.status.errorDark,
    position: "absolute",
    bottom: "4rem",
  },
  fieldTitle: {
    backgroundColor: theme.palette.grey.lightWarmGrey,
    textAlign: "center",
    margin: "20px 0",
    padding: "5px",
  },
  reportType: {
    borderRadius: "25px",
    background: theme.palette.primary.main,
    color: theme.palette.grey.white,
    padding: "5px",
    width: "160px",
    fontSize: "1rem",
    textAlign: "center",
    fontWeight: 500,
  },
};

const SingleDailyReport = ({ reportId = undefined }) => {
  const [report, loading] = useGetDailyReport(reportId);
  const [hasError, showErrorMessage] = useState(false);
  const [errorMessageLabel, setErrorMessageLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reportStatus, setReportStatus] = useState("");

  const seeUncompletedCaptureReason = (input) => {
    const siteIDs = report.notCompletedSiteId;

    const result = input.map((element, i) => (
      <li key={i}>
        <span>
          <strong>siteID:</strong>
          &emsp;{siteIDs[i]} &emsp;
        </span>

        <span>
          {element.reason != uncompleteReasonsOptions[4]
            ? element.reason
            : element.details}
        </span>
      </li>
    ));
    return result;
  };
  const seeUncompletedUploadReason = (input) => {
    const siteIDs = report.notCompletedUploadSiteId;

    const result = input.map((element, i) => (
      <li key={i}>
        <span>
          <strong>siteID:</strong>
          &emsp;{siteIDs[i]} &emsp;
        </span>

        <span>{element.details}</span>
      </li>
    ));
    return result;
  };
  const markRead = useCallback(() => {
    updateDailyReportStatus(firebase, reportId, dailyReportStatus.read)
      .then(() => {
        setReportStatus(dailyReportStatus.read);
      })
      .catch(() => {
        showErrorMessage(true);
        setErrorMessageLabel("This report does not exist");
        setErrorMessage("Make sure that the report you are changing exists");
      });
  }, [reportId]);
  useEffect(() => {
    if (!firebase) return;
    markRead();
  }, [markRead, reportId]);

  useEffect(() => {
    if (!firebase) return;
    getDailyReportStatus(firebase, reportId).then((report) => {
      setReportStatus(report);
    });
  }, [reportId]);

  const markUnread = () => {
    updateDailyReportStatus(firebase, reportId, dailyReportStatus.unread)
      .then(() => {
        setReportStatus(dailyReportStatus.unread);
      })
      .catch(() => {
        showErrorMessage(true);
        setErrorMessageLabel("This report does not exist");
        setErrorMessage("Make sure that the report you are changing exists");
      });
  };

  const closeTriggered = () => {
    setTimeout(function () {
      showErrorMessage(false);
    }, 300);
  };

  return (
    <Layout pageTitle="Daily Report" loading={loading} hasSideMenu>
      {!loading && (
        <>
          <Box sx={styles.root}>
            <GridContainer styles={styles.pilotProfile} gutters>
              <GridColumn widthLarge="25%" gutters styles={styles.leftColumn}>
                <Box sx={styles.mainInfo}>
                  <ul style={styles.pilotInfoUl}>
                    {report.province.map((element, i) => (
                      <li key={i}>
                        <Typography variant="h4">{element}</Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
                <Box sx={styles.statusColumn}>
                  <Typography variant="h5">Status</Typography>
                  <Typography
                    variant="h4"
                    style={
                      reportStatus == dailyReportStatus.read
                        ? styles.read
                        : styles.unread
                    }
                  >
                    {reportStatus}
                  </Typography>
                  {reportStatus === dailyReportStatus.unread ? (
                    <AppButton
                      label="Mark as read"
                      onClick={markRead}
                      look="green"
                    />
                  ) : (
                    <AppButton
                      label="Mark as unread"
                      onClick={() => markUnread()}
                      look="negative"
                    />
                  )}
                </Box>
                {hasError ? (
                  <ErrorMessage
                    show={true}
                    errorMessageLabel={errorMessageLabel}
                    errorMessage={errorMessage}
                    closeTriggered={closeTriggered}
                  />
                ) : null}
              </GridColumn>

              <GridColumn widthLarge="75%" gutters>
                <Box sx={styles.dataColumn}>
                  <Box>
                    <p style={styles.reportType}>{report.reportType} report</p>
                  </Box>
                  <Box sx={styles.fieldTitle}>
                    <Typography variant="h5">Date and location</Typography>
                  </Box>
                  <ul style={styles.pilotInfoUl}>
                    <li>
                      <span style={styles.plabel}>Operator&apos;s name</span>
                      <span style={styles.data}>{report.name}</span>
                    </li>

                    <li>
                      <span style={styles.plabel}>
                        Mission date as submitted by pilot
                      </span>
                      <span style={styles.data}>
                        {report.missionDate.toDate().toLocaleDateString()}
                      </span>
                    </li>

                    <li>
                      <span style={styles.plabel}>Date report created</span>
                      <span style={styles.data}>
                        {DateTime.fromISO(report.dateCreated).toFormat(
                          "dd/MM/yyyy"
                        )}
                      </span>
                    </li>
                    <li>
                      <span style={styles.plabel}>Provinces</span>
                      <span style={styles.data}>
                        <ul style={styles.pilotInfoUl}>
                          {report.province.map((element, i) => (
                            <li key={i}> {element}</li>
                          ))}
                        </ul>
                      </span>
                    </li>
                  </ul>
                  <Box sx={styles.fieldTitle}>
                    <Typography variant="h5">Sites</Typography>
                  </Box>

                  <ul style={styles.pilotInfoUl}>
                    {report.reportType === reportType.capture && (
                      <>
                        <li>
                          <span style={styles.plabel}>
                            Number of sites completed
                          </span>
                          <span style={styles.data}>
                            {report.completedSiteNumber}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>
                            Completed siteID&apos;s
                          </span>
                          <span style={styles.data}>
                            {report.completedSiteId ? (
                              report.completedSiteId.map((element, i) => (
                                <span key={i}> {element} &ensp; </span>
                              ))
                            ) : (
                              <span> not inserted </span>
                            )}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>
                            Number of sites not completed
                          </span>
                          <span style={styles.data}>
                            {report.notCompletedSiteNumber}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>
                            Uncompleted siteID&apos;s
                          </span>
                          <span style={styles.data}>
                            {report.notCompletedSiteId.map((element, i) => (
                              <span key={i}> {element} &ensp;</span>
                            ))}{" "}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>Why not completed?</span>
                          <span style={styles.data}>
                            <ul style={styles.pilotInfoUl}>
                              {seeUncompletedCaptureReason(
                                report.notCompletedReason
                              )}
                            </ul>
                          </span>
                        </li>
                      </>
                    )}
                    {report.reportType === reportType.upload && (
                      <React.Fragment>
                        <li>
                          <span style={styles.plabel}>
                            Number of sites with a completed upload
                          </span>
                          <span style={styles.data}>
                            {report.completedUploadedSiteNumber}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>
                            Completed uploaded siteID&apos;s
                          </span>
                          <span style={styles.data}>
                            {report.uploadedSiteId.map((element, i) => (
                              <span key={i}> {element} &ensp; </span>
                            ))}{" "}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>
                            Number of sites with a uncompleted upload
                          </span>
                          <span style={styles.data}>
                            {report.notCompletedUploadedSiteNumber}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>
                            Uncompleted upload siteID&apos;s
                          </span>
                          <span style={styles.data}>
                            {report.notCompletedUploadSiteId.map(
                              (element, i) => (
                                <span key={i}> {element} &ensp;</span>
                              )
                            )}{" "}
                          </span>
                        </li>
                        <li>
                          <span style={styles.plabel}>Why not completed?</span>
                          <span style={styles.data}>
                            <ul style={styles.pilotInfoUl}>
                              {seeUncompletedUploadReason(
                                report.notCompletedUploadDetails
                              )}
                            </ul>
                          </span>
                        </li>
                      </React.Fragment>
                    )}
                    <li>
                      <span style={styles.plabel}>Other comments</span>
                      <span style={styles.data}>{report.otherComments}</span>
                    </li>
                  </ul>
                </Box>
              </GridColumn>
            </GridContainer>
            <br />
          </Box>
        </>
      )}
    </Layout>
  );
};
SingleDailyReport.propTypes = {
  reportId: PropTypes.string,
};

// SingleDailyReport.defaultProps = {
//   reportId: undefined,
// };

export default SingleDailyReport;
