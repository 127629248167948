import { AppBar, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  appBar,
  StyledTab,
  StyledTabs,
} from "../../../../../lib/styleConstants";
import TabPanel from "../../../../global/elements/TabPanel";
import TableWithoutPagination from "../../../../global/Table/TableWithoutPagination";
import {
  clientFeedbackFieldsML,
  clientFeedbackFieldsSL,
  orderCollectionData,
} from "../../../../../lib/constants";
import AppButton from "../../../../global/elements/AppButton";
import theme from "../../../../../theme";
import PropTypes from "prop-types";
import FeedBackStarField from "../../../missions/FeedbackTab/FeedBackStarField";
import EmptyDataIndicator from "src/components/global/elements/EmptyDataIndicator";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = {
  feedbackContainer: {
    height: "100vh", // Full viewport height
    display: "flex",
    flexDirection: "column",
  },
  tabPanelContainer: {
    flex: 1, // Fills remaining space
    overflowY: "auto", // Scrollable content
    // padding: "16px",
  },
  clientColor: { color: theme.palette.primary.main },
  buttonContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    padding: "16px",
    boxShadow: `0 -2px 8px ${theme.palette.grey.warmGrey} `,
    display: "flex",
    justifyContent: "space-between",
  },
};
const FeedbackDialogueBody = ({
  singleLocData,
  multiLocData,
  submitFeedBackFcn,
  remindMeLaterFcn,
  permanentSkipFcn,
  submitFeedbackSuccess,
}) => {
  const [tabValue, setTabValue] = useState(singleLocData?.length > 0 ? 0 : 1);

  const handleTabChange = (e, newTabValue) => {
    setTabValue(newTabValue);
  };

  const onChangeHandlerRating = (value, data) => {
    const orderType =
      tabValue === 0
        ? orderCollectionData.missions
        : orderCollectionData.projects;
    const orderName = tabValue === 0 ? data.missionName : data.projectName;
    submitFeedBackFcn(value, data.id, orderType, orderName);
  };

  const customFields = (column, data) => {
    switch (column.field) {
      case "actionRatingSL":
        return (
          <FeedBackStarField
            label="How would you rate your Globhe Experience?"
            name="overAllExperience"
            value={data.overAllExperience}
            onChange={(name, value) => onChangeHandlerRating(value, data)}
            precision={0.5}
          />
        );
    }
  };

  return (
    <Box sx={styles.feedbackContainer}>
      <Typography variant="h5" mb={3}>
        Give your valuable feedback
      </Typography>
      <AppBar position="static" sx={appBar} elevation={0}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant="standard"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <StyledTab
            key="missions"
            label="Single location orders"
            {...a11yProps(0)}
          />
          <StyledTab
            key="projects"
            label="Multi location orders"
            {...a11yProps(1)}
          />
        </StyledTabs>
      </AppBar>
      <Box sx={styles.tabPanelContainer} mb={13}>
        <TabPanel value={tabValue} index={0} key="missions">
          {singleLocData?.length > 0 ? (
            <TableWithoutPagination
              columns={clientFeedbackFieldsSL}
              customFields={customFields}
              datas={singleLocData}
            />
          ) : (
            <EmptyDataIndicator
              title={"You&apos;ve rated all your orders!"}
              subTitle={
                "Thank you for sharing your feedback - it helps us serve youbetter!"
              }
            />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1} key="projects">
          {multiLocData?.length > 0 ? (
            <TableWithoutPagination
              columns={clientFeedbackFieldsML}
              customFields={customFields}
              datas={multiLocData}
            />
          ) : (
            <EmptyDataIndicator
              title="You've rated all your orders!"
              subTitle="Thank you for sharing your feedback - it helps us serve you better!"
            />
          )}
        </TabPanel>
      </Box>

      <Box sx={styles.buttonContainer}>
        <AppButton
          look="green"
          label="Remind me later"
          onClick={remindMeLaterFcn}
        />
        <AppButton look="green" label="Skip" onClick={permanentSkipFcn} />
        <AppButton
          look="green"
          label="Submit"
          onClick={submitFeedbackSuccess}
        />
      </Box>
    </Box>
  );
};

FeedbackDialogueBody.propTypes = {
  singleLocData: PropTypes.object.isRequired,
  multiLocData: PropTypes.object.isRequired,
  submitFeedBackFcn: PropTypes.func.isRequired,
  remindMeLaterFcn: PropTypes.func.isRequired,
  permanentSkipFcn: PropTypes.func.isRequired,
  submitFeedbackSuccess: PropTypes.func.isRequired,
};

export default FeedbackDialogueBody;
