import React from "react";
import PropTypes from "prop-types";
import { getCurrentDate } from "../../../../lib/helpers";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../../theme";

const styles = {
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `0.4px solid ${theme.palette.black.sandyBlack}`,
    borderTop: `0.4px solid ${theme.palette.black.sandyBlack}`,
    alignItems: "center",
  },
  cardEmail: {
    flex: { xs: 2, sm: 4 },
  },
  cardDate: {
    flex: 1,
    textAlignLast: "right",
  },
  cardRemove: {
    flex: { xs: 0.4, sm: 1 },
    textAlignLast: { xs: "end", sm: "center" },
    cursor: "pointer",
    marginTop: "6px",
  },
};

const CollabCard = ({ data = [], removeCollaboratorInMission = undefined }) => {
  return (
    <Box sx={styles.cardContainer}>
      <Typography variant="body1" sx={styles.cardEmail}>
        {data.email}
      </Typography>
      <Typography variant="body1" sx={styles.cardDate}>
        {getCurrentDate(data.dateCreated)}
      </Typography>
      <Box sx={styles.cardRemove}>
        <CloseIcon
          onClick={() =>
            removeCollaboratorInMission({
              email: data.email,
              colloborationId: data.id,
            })
          }
          fontSize="medium"
        />
      </Box>
    </Box>
  );
};
CollabCard.propTypes = {
  data: PropTypes.object,
  removeCollaboratorInMission: PropTypes.func,
};

export default CollabCard;
