import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import SingleLocationMap from "../../../CreateOrder/Components/SingleLocationMap";
import MissionContext from "./MissionContext";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { getCountryName } from "../../../../../lib/helpers";
import EditRoadSharpIcon from "@mui/icons-material/EditRoadSharp";
import { getTabBgColor } from "../../../../../lib/styleConstants";
import StatusBadge from "../../../../global/elements/StatusBadge";
import {
  colorStatus,
  missionPlanningStates,
  missionPlanningStatusLabel,
  missionTypeConstant,
  userRoles,
} from "../../../../../lib/constants";
import { getUser } from "../../../../../services/auth";
import theme from "../../../../../theme";
import AppButton from "../../../../global/elements/AppButton";

const styles = {
  text: {
    margin: "auto",
    marginLeft: "10px",
  },
  typeBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
  },
  textMargin: {
    marginBottom: "0 !important",
  },
  greyText: {
    color: theme.palette.black.paragraphText,
  },
  statusGrid: {
    textAlign: "right",
  },
  rightAlign: {
    justifyContent: "right",
  },
  image: {
    width: "100%",
    margin: "auto",
    maxHeight: "100px",
    objectFit: "contain",
  },
  imageContainer: {
    alignSelf: "center",
  },
};

const AdminClientMissionPlanning = () => {
  const missionContextData = useContext(MissionContext);
  const currentUserRole = getUser("user").role;

  const getStatusLabels = (status) => {
    if (missionContextData?.mission.status === missionPlanningStates.canelled) {
      return missionPlanningStatusLabel.canelled;
    } else if (
      missionContextData?.mission.status === missionPlanningStates.completed
    ) {
      return missionPlanningStatusLabel.completed;
    }
    switch (status) {
      case missionPlanningStates.canelled: {
        return missionPlanningStatusLabel.canelled;
      }
      case missionPlanningStates.reviewed: {
        return missionPlanningStatusLabel.reviewed;
      }
      case missionPlanningStates.notFeasible: {
        return missionPlanningStatusLabel.notFeasible;
      }
      case missionPlanningStates.reschedule: {
        return missionPlanningStatusLabel.reschedule;
      }
      case missionPlanningStates.readyToUpload: {
        return missionPlanningStatusLabel.readyToUpload;
      }
      case missionPlanningStates.pendingUpload: {
        return missionPlanningStatusLabel.pendingUpload;
      }
      case missionPlanningStates.inReview: {
        return missionPlanningStatusLabel.inReview;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          sx={
            missionContextData?.missionPlanning?.data?.photoProof?.length > 0
              ? ""
              : { height: "200px" }
          }
        >
          <SingleLocationMap
            orderType={missionContextData?.mission?.orderType}
            locationMap={missionContextData?.mission?.locationMap}
            locationLabel={missionContextData?.mission?.locationLabel}
            areaSize={missionContextData?.mission?.areaSize}
            showOrderType={false}
            hideLocation={true}
          />
        </Grid>
        <Grid container item xs={12} sm={8} md={9}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h6" sx={styles.text}>
                  {missionContextData?.mission?.missionName}
                </Typography>
                <Typography variant="body1" sx={styles.text}>
                  {missionContextData?.mission?.id}
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} mt={3}>
              <Grid item xs={6} sx={styles.typeBox}>
                <LocationOnOutlinedIcon fontSize="medium" />
                <Typography variant="body1" sx={styles.textMargin} ml={1}>
                  {getCountryName(missionContextData?.mission)}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={styles.typeBox}>
                <EditRoadSharpIcon fontSize="medium" />
                <Typography variant="body1" sx={styles.textMargin} ml={1}>
                  {missionContextData?.mission?.areaSize?.toFixed(2)} sq km
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={styles.statusGrid}>
            {getStatusLabels(missionContextData?.missionPlanning?.status) && (
              <StatusBadge
                name={
                  getStatusLabels(missionContextData?.missionPlanning?.status)
                    ?.label
                }
                status={
                  getStatusLabels(missionContextData?.missionPlanning?.status)
                    ?.color
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" mb={1}>
              Issue :{" "}
            </Typography>
            <Grid item xs={12} mb={1}>
              <StatusBadge
                name={
                  missionContextData?.missionPlanning?.data?.selectedReason ||
                  "N/A"
                }
                status={
                  currentUserRole === userRoles.client
                    ? colorStatus?.globheGreen
                    : colorStatus.blue
                }
              />
            </Grid>
            <Typography variant="subtitle1Med" sx={styles.greyText}>
              {missionContextData?.missionPlanning?.data?.detailedReason ||
                "N/A"}
            </Typography>
          </Grid>
          {missionContextData?.missionPlanning?.data?.photoProof?.length >
            0 && (
            <Grid
              container
              mt={2}
              item
              xs={12}
              spacing={1}
              sx={styles.imageContainer}
            >
              <Grid ml={1} xs={12}>
                <Typography variant="h5">Image proofs : </Typography>
              </Grid>
              {missionContextData?.missionPlanning?.data?.photoProof?.map(
                (photo, i) => {
                  return (
                    <Grid item xs={6} sm={4} md={3} key={i}>
                      <Box
                        component={"img"}
                        src={photo.fileUrl}
                        sx={styles.image}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          )}
        </Grid>
        {currentUserRole === userRoles.client &&
          (missionContextData?.missionPlanning?.status ===
            missionPlanningStates.notFeasible ||
            missionContextData?.missionPlanning?.status ===
              missionPlanningStates.pendingUpload) && (
            <Grid container item xs={12} spacing={2} sx={styles.rightAlign}>
              <Grid item>
                <AppButton
                  label="Contact sales"
                  look="inverted"
                  onClick={(e) =>
                    missionContextData.handleTabChange(
                      e,
                      missionContextData?.mission.missionType ===
                        missionTypeConstant.clientOrder
                        ? 3
                        : 2
                    )
                  }
                />
              </Grid>
              <Grid item>
                <AppButton
                  label="Order a new data"
                  look={"green"}
                  url="/app/order/create-order"
                  externalLink
                />
              </Grid>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default AdminClientMissionPlanning;
