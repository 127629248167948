import PropTypes from "prop-types";
import React from "react";
import useGetPilotList from "src/hooks/useGetPilotList";
import FilterPilotsForm from "./FilterPilotsForm";
import PilotInviteList from "./PilotInviteList";
import GlobalTable from "./GlobalTable";
import { columnForDroneOperators } from "../../../lib/constants";
import { navigate } from "gatsby";
import { Box } from "@mui/material";
import UniversalLoader from "../../global/elements/UniversalLoader";
import Rating from "@mui/material/Rating";

const style = {
  loaderDiv: {
    paddingTop: "18%",
  },
  fontWeghtStyles: {
    fontWeight: "200 !important",
  },
};

const PilotSearch = ({
  missionData = null,
  assignPilotHandler = null,
  className = "",
  assignPilot = false,
}) => {
  const [
    filteredPilotList,
    pilotsLoading,
    sortBy,
    currentPageNumber,
    isLastPilot,
    setSortBy,
    setCurrentPageNumber,
    setFilterorSortTriggered,
    setFilterBy,
    setLimit,
    filterBy,
    limit,
  ] = useGetPilotList();

  const sortByHandler = (sort) => {
    if (sort === sortBy[0]) {
      const order = sortBy[1] === "asc" ? "desc" : "asc";
      setSortBy([sort, order]);
      setFilterorSortTriggered(true);
    } else {
      setSortBy([sort, "asc"]);
      setFilterorSortTriggered(true);
    }
  };

  const limitHandler = (value) => {
    setLimit(value);
    setFilterorSortTriggered(true);
  };

  const valueChangeHandler = (value, field, filterFields) => {
    let filterDataNew = filterBy;
    if ((value == "") & (field == "")) {
      let changedField = Object.keys(filterDataNew[0]).filter(
        (fld) => filterFields.indexOf(fld) < 0
      );
      changedField.forEach((chgFld) => {
        if (
          chgFld == "Drone" ||
          chgFld == "Available Locations" ||
          chgFld == "Sensors/Cameras"
        ) {
          filterDataNew[0][chgFld] = [];
        } else {
          filterDataNew[0][chgFld] = "";
        }
      });
    } else if ((value == "InitialLoad") & (field == "InitialLoad")) {
      let filterDataSaved = JSON.parse(localStorage.getItem("filterData"));
      filterDataNew = filterDataSaved;
    } else {
      filterDataNew[0][field] = value;
    }

    let filterData = [filterDataNew[0], true];
    localStorage.setItem("filterData", JSON.stringify(filterData));
    localStorage.setItem("filterFieldsPilot", filterFields);
    setFilterBy(filterData);
    setFilterorSortTriggered(true);
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      // case "date": {
      //   return (
      //     <Typography variant="h5" component="h6">
      //       {DateTime.fromISO(value["dateCreated"]).toISODate()}
      //     </Typography>
      //   );
      // }
      // case "array":
      //   return (
      //     <Typography variant="h5" component="h6">
      //       {value["equipment"] && value["equipment"].length > 0
      //         ? value["equipment"].toString()
      //         : "n/a"}
      //     </Typography>
      //   );
      case "rating":
        return (
          <Rating
            name={"overallRating"}
            value={value?.ratings?.overallRating || 0}
            precision={0.5}
            readOnly
          />
        );

      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    navigate(`/app/pilots/${value.id}`);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPageNumber;
    if (type == "next") {
      if (!(isLastPilot.lastPageNumber === currentPageNumber)) {
        pageNumberNew = pageNumberNew + 1;
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
    }
    setCurrentPageNumber(pageNumberNew);
  };

  return pilotsLoading ? (
    <Box sx={{ ...style.loaderDiv }}>
      <UniversalLoader />
    </Box>
  ) : (
    <Box className={className}>
      <FilterPilotsForm valueChangeHandler={valueChangeHandler} />
      {missionData ? (
        <PilotInviteList
          pilots={filteredPilotList}
          loading={pilotsLoading}
          sortByHandler={sortByHandler}
          missionData={missionData}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={currentPageNumber}
          isLastPage={isLastPilot.isLast}
          lastPage={isLastPilot.lastPageNumber}
        />
      ) : (
        <GlobalTable
          columns={columnForDroneOperators}
          allData={filteredPilotList}
          sortByHandler={sortByHandler}
          valueConvertHandler={valueConvertHandler}
          goToPageFcn={assignPilot ? assignPilotHandler : goToPageFcn}
          loading={pilotsLoading}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={currentPageNumber}
          isLastPage={isLastPilot.isLast}
          lastPage={isLastPilot.lastPageNumber}
        />
      )}
    </Box>
  );
};

PilotSearch.propTypes = {
  missionData: PropTypes.object,
  assignPilotHandler: PropTypes.func,
  className: PropTypes.string,
  assignPilot: PropTypes.bool,
  inviteFromCountry: PropTypes.bool,
  country: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

export default PilotSearch;
