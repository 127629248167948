import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import useGetDocumentsList from "../../../hooks/useGetdocumentsList";
import NoDocuments from "./Components/NoDocuments";
import { useStateIfMounted } from "use-state-if-mounted";
import FilterComponent from "../../global/elements/FilterComponent";
import GlobalTable from "../admin/GlobalTable";
import UniversalLoader from "../../global/elements/UniversalLoader";
import {
  documentTable,
  enterPriseRoleType,
  enterPriseUserRole,
  filterFiledForDocuments,
  userRoles,
} from "../../../lib/constants";
import AddDocPopup from "./Components/AddDocPopup";
import { createDocument, deleteDocument } from "../../../services/documents";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "gatsby";
import theme from "../../../theme";
import DeleteEnterpriseDoc from "./Components/DeleteEnterpriseDoc";
import { useSnackbar } from "notistack";
import AppButton from "../../global/elements/AppButton";
import PropTypes from "prop-types";

const styles = {
  menuBar: {
    display: "flex",
    position: "relative",
    flexDirection: "row-reverse",
  },
  menuBarStyle: {
    width: "140px",
    height: "auto",
    padding: "10px",
    marginLeft: "15px",
    background: theme.palette.grey.lightWarmGrey,
    position: "absolute",
    right: "25px",
    top: "-35px",
  },
  paddingText: {
    padding: "10px 5px",
    "&:hover": {
      background: theme.palette.primary.palePistachio,
    },
  },
  nonClickable: {
    padding: "10px 5px",
    cursor: "not-allowed",
    "&:hover": {
      background: theme.palette.status.disabledDark,
    },
  },
  linkStyle: {
    textDecoration: "none",
  },
  mtop: { marginTop: "40px" },
  btnCls: {
    width: "100%",
  },
};

const DocumentMainPage = ({ enterpriseId, userData }) => {
  const [openAddDocPopup, setOpenAddDocPopup] = useStateIfMounted(false);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const [deleteDocPopup, setDeleteDocPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    documents,
    setDocuments,
    allDocuments,
    setAllDocuments,
    triggerReload,
    setTriggerReload,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    loading,
    isLastPage,
    lastPageNumber,
    limit,
    setLimit,
    setLastLoadedDocument,
    setLastPageNumber,
    setDataLoadedTill,
    setIslastPage,
  } = useGetDocumentsList(enterpriseId);

  const clearDataAndTriggerReload = () => {
    setLastLoadedDocument(null);
    setCurrentPage(1);
    setAllDocuments([]);
    setDocuments([]);
    setLastPageNumber(null);
    setDataLoadedTill(null);
    setIslastPage(false);
    setTriggerReload(!triggerReload);
  };

  const limitHandler = (value) => {
    setLimit(value);
    clearDataAndTriggerReload();
  };

  const paginationBtnClick = (type) => {
    if (type === "next") {
      if (!(lastPageNumber === currentPage)) {
        setCurrentPage(currentPage + 1);
        setTriggerReload(!triggerReload);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setLastLoadedDocument(null);
        setTriggerReload(!triggerReload);
      }
    }
  };
  const valueConvertHandler = (field, row) => {
    switch (field) {
      case "menuBar": {
        return (
          <Box sx={styles.menuBar}>
            <MoreVertIcon
              onClick={() =>
                menuOpenId === row.id
                  ? setMenuOpenId(null)
                  : setMenuOpenId(row.id)
              }
              fontSize="medium"
            />
            {menuOpenId === row.id && (
              <Box sx={styles.menuBarStyle}>
                <Link
                  to={row.docUrl}
                  target={"_blank"}
                  style={styles.linkStyle}
                >
                  <Typography sx={styles.paddingText} variant="body1">
                    Download
                  </Typography>
                </Link>
                <Typography
                  sx={
                    userData?.role === userRoles.admin ||
                    userData?.roleType === enterPriseUserRole.admin
                      ? styles.paddingText
                      : styles.nonClickable
                  }
                  variant="body1"
                  onClick={() => {
                    if (
                      userData?.role === userRoles.admin ||
                      userData?.roleType === enterPriseUserRole.admin
                    ) {
                      setDeleteDocPopup(true);
                      setMenuOpenId(row.id);
                    }
                  }}
                >
                  Delete
                </Typography>
              </Box>
            )}
          </Box>
        );
      }
    }
  };

  const submitDoc = async (values) => {
    values.enterPriseId = enterpriseId;
    values.uploadedByRole = userData.role;
    const status = await createDocument(values);
    if (status.status === "Success") {
      setOpenAddDocPopup(false);
      enqueueSnackbar("Document added successfully!!", { variant: "success" });
      clearDataAndTriggerReload();
    }
  };

  const deleteFcn = () => {
    deleteDocument(menuOpenId).then((snapshot) => {
      if (snapshot.status === "Success") {
        setAllDocuments(allDocuments.filter((doc) => doc.id !== menuOpenId));
        setDocuments(documents.filter((doc) => doc.id !== menuOpenId));
        setMenuOpenId(null);
        setDeleteDocPopup(false);
        enqueueSnackbar("Document removed successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Fail to remove document!", { variant: "danger" });
      }
    });
  };
  return (
    <>
      {(documents?.length == 0 && !filter.docName) || !enterpriseId ? (
        <NoDocuments
          setOpenAddDocPopup={setOpenAddDocPopup}
          userRole={userData.role}
        />
      ) : (
        <Box sx={styles.mtop}>
          <Grid container spacing={3}>
            <Grid item xs={8} md={9}>
              <FilterComponent
                filterFields={filterFiledForDocuments}
                multiple={true}
                filterFormName={"mainFilterFields"}
                filter={filter}
                setFilter={setFilter}
                clearDataAndTriggerReload={clearDataAndTriggerReload}
              />
            </Grid>
            <Grid item xs={4} md={3}>
              <AppButton
                label={"Add Document +"}
                look={"green"}
                onClick={() => setOpenAddDocPopup(true)}
                noIcon
                addtionalStyle={styles.btnCls}
              />
            </Grid>
          </Grid>
          <br />
          {!loading ? (
            <GlobalTable
              columns={documentTable}
              allData={documents}
              valueConvertHandler={valueConvertHandler}
              goToPageFcn={() => {}}
              limitHandler={limitHandler}
              limit={limit}
              paginationBtnClick={paginationBtnClick}
              currentPage={currentPage}
              isLastPage={isLastPage}
              lastPage={lastPageNumber}
            />
          ) : (
            <UniversalLoader />
          )}
        </Box>
      )}

      <AddDocPopup
        openAddDocPopup={openAddDocPopup}
        setOpenAddDocPopup={setOpenAddDocPopup}
        submitDoc={submitDoc}
        enterpriseId={enterpriseId}
      />
      <DeleteEnterpriseDoc
        deletePopup={deleteDocPopup}
        setDeletePopup={setDeleteDocPopup}
        deleteFcn={deleteFcn}
      />
    </>
  );
};

DocumentMainPage.propTypes = {
  enterpriseId: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
};

export default DocumentMainPage;
