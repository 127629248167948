import React, { useContext, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import AppContext from "../../../contexts/AppContext";
import { Box } from "@mui/material";
import theme from "../../../theme";
import TopMenuIcons from "./TopMenuIcons";
import CurrencyPopup from "./CurrencyPopup";
import {
  defaultCurrency,
  enterPriseUserRole,
  userRoles,
  users,
} from "../../../lib/constants";
import { updateEnterPrise } from "../../../services/enterprise";
import { updateClient } from "../../../services/client";
import { useSnackbar } from "notistack";
import { setUserData } from "../../../services/auth";
import IconButton from "@mui/material/IconButton";

const styles = {
  currencyBox: {
    color: theme.palette.black.coolGrey,
  },
  currencyContainer: { marginLeft: { xs: 0, md: "10px" } },
};

const Currency = () => {
  const appContext = useContext(AppContext);

  const enterPriseClient = appContext?.userData?.enterPriseId ? true : false;

  const enterPriseAdmin =
    enterPriseClient &&
    appContext?.userData?.roleType == enterPriseUserRole.admin
      ? true
      : false;

  const regularClient =
    !enterPriseClient && appContext?.userData?.role == userRoles.client
      ? true
      : false;

  const selectedCurrency = enterPriseClient
    ? appContext?.enterPrise?.currency || defaultCurrency
    : appContext?.userData?.currency || defaultCurrency;

  const id = "currency-panel";
  const [anchorElCurrency, setAnchorElCurrency] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickCurrency = (event) => {
    setAnchorElCurrency(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorElCurrency(null);
  };

  const onSelectCurrency = async (currency) => {
    try {
      if (enterPriseClient) {
        await updateEnterPrise(appContext?.enterPrise?.id, { currency });
        appContext?.setEnterPrise({ ...appContext?.enterPrise, currency });
      } else {
        await updateClient(appContext?.userData?.id, { currency });
        setUserData({ ...appContext?.userData, currency }, users.userProfile);
        appContext?.setUserData({ ...appContext?.userData, currency });
      }
      setAnchorElCurrency(null);
    } catch (e) {
      console.log("Error in changing the currency");
      enqueueSnackbar("Error in changing currency", { variant: "error" });
    }
  };

  return (
    <Box
      sx={styles.currencyBox}
      aria-describedby={id}
      onClick={handleClickCurrency}
    >
      <IconButton
        size="large"
        aria-label="show selected currency"
        color="inherit"
        aria-haspopup="true"
        sx={styles.currencyContainer}
      >
        <TopMenuIcons
          onClick={() => {}}
          addBackgroundCircle
          showText={true}
          textValue={getSymbolFromCurrency(selectedCurrency)}
          showIcon={false}
        />
      </IconButton>
      {(enterPriseAdmin || regularClient) && (
        <CurrencyPopup
          anchorElCurrency={anchorElCurrency}
          handleClose={handleClose}
          id={id}
          selectedCurrency={selectedCurrency}
          onSelectCurrency={onSelectCurrency}
        />
      )}
    </Box>
  );
};

export default Currency;
