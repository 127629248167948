import { Box, AppBar, Typography } from "@mui/material";
import { BlockOutlined, Done, WarningAmberOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  StyledTab,
  StyledTabs,
  singleMissionTitle,
} from "../../../../lib/styleConstants";
import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../../../Layout";
import { useGetMission } from "../../../../hooks/useGetMission";
import { useGetPilotPackages } from "../../../../hooks/useGetPilotPackages";
import { useGetDeliveryPackages } from "../../../../hooks/useGetDeliveryPackages";
import { useStateIfMounted } from "use-state-if-mounted";
import firebase from "src/firebase";
import theme from "../../../../theme";
import MissionContext from "./components/MissionContext";
import OrderTopNavComponent from "./components/OrderTopNavComponent";
import { checkAdmintype, getUser } from "../../../../services/auth";
import {
  DeliveryPackageStatus,
  InvoiceText,
  PilotPackageStatus,
  adminClientProgressStatus,
  admintype,
  contractStatusLabel,
  interestInviteStatus,
  missionStatus,
  missionTypeConstant,
  orderLogStatus,
  packageTypeConstant,
  statusAlertSeverity,
  userRoles,
  AdminNextActions,
  quoteStatus,
  missionPlanningStates,
  paymentStatus,
  orderCollectionData,
} from "../../../../lib/constants";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";
import DeliveryPackagesTab from "../FilesPackages/DeliveryPackagesTab";
import EmptyMessage from "../shared/EmptyMessage";
import { createInvoice } from "../../../../services/invoices";
import {
  assignPilotToMission,
  completeMission,
  getCurrentQuotesStatus,
  getDeliveryPackageByMissionId,
  getInvoiceByDeliveryPackageId,
  getMissionPlanningStatus,
} from "../../../../services/missions";
import {
  rejectPilotPackage,
  updatePilotPackageStatusForEmptyData,
} from "../../../../services/pilot-packages";
import PilotPackagesTab from "../FilesPackages/PilotPackagesTab";
import { addDeliveryFileV2 } from "../../../../services/delivery-files";
import { getDateTimeWithFormat } from "../../../../lib/helpers";
import TabPanel from "../../../global/elements/TabPanel";
import QuoteDetail from "../../quotes/QuoteDetail";
import OrderDetailsComponent from "./components/OrderDetailsComponent";
import CountryPopup from "../../../invitePopup/countryPopup";
import InvitePopup from "../../../invitePopup/inviteModal";
import countries from "src/lib/countries";
import MissionDetailsEdit from "../MissionDetailsEdit";
import PilotSearch from "../../admin/PilotSearch";
import { createOrderStatus } from "../../../../services/orderStatus";
import {
  assignPilotToProject,
  createProjectContract,
  getBasicProjectDetails,
} from "../../../../services/project";
import {
  createContract,
  getMissionContract,
} from "../../../../services/contracts";
import { findAndUpdateInterest } from "../../../../services/interest-invite";
import { createNotification } from "../../../../services/notification";
import CreateInvoicePrompt from "../singleMissionPage/createInvoice";
import UserNextActions from "./components/UserNextActions";
import { getClientById, getEnterPrise } from "../../../../services/client";
import PilotSearchBackNav from "../../admin/PilotSearchBackNav";
import MissionPlanning from "../MissionPlanning";
import AppButton from "../../../global/elements/AppButton";
import { useSnackbar } from "notistack";
import TwoWayCommunicationAdmin from "../../../global/TwoWayCommunication/TwoWayCommunicationAdmin";
import { getPilot } from "../../../../services/pilots";
import FeedbackOrder from "../FeedbackTab/FeedbackOrder";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = {
  root: {
    "& .next-mission-step": {
      padding: "2rem",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      marginTop: "2rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .next-mission-step .remove-pilot-btn": {
      marginTop: "2rem",
    },
    "& .column-content": {
      padding: "2rem",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      marginTop: "2rem",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& .mission-info": {
      position: "relative",
      alignContent: "flex-start",
    },
    "& .mission-info .edit-button": {
      position: "absolute",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100%",
    },
    "& .mission-info.admin": { paddingBottom: "100px !important" },
    "& h2": { textAlign: "center" },

    "& .single-mission": {
      paddingBottom: "2rem",
      minHeight: "calc(100vh - 170px)",
    },
    "& .pick-pilot": { padding: "4rem 0" },
  },
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "green",
  },
  packageTabLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const PageModes = Object.freeze({
  DEFAULT: 0,
  INVITE_PILOTS: 1,
  ASSIGN_PILOTS: 2,
  EDIT_MISSION: 3,
});

const AdminSingleMissionPage = ({ missionID }) => {
  const currentUser = getUser("userProfile");
  const isAdmin = currentUser?.role === userRoles.admin;
  const { enqueueSnackbar } = useSnackbar();

  const {
    mission,
    loading: loadingMission,
    refreshMission,
  } = useGetMission(missionID);

  const {
    packages: pilotPackages = [],
    loading: loadingPilotPackages,
    refreshPackages: refreshPilotPackages,
  } = useGetPilotPackages(missionID);

  const {
    packages: deliveryPackages = [],
    loading: loadingDeliveryPackages = true,
    refreshPackages: refreshDeliveryPackages,
  } = useGetDeliveryPackages(missionID);

  const [tabValue, setTabValue] = useStateIfMounted(0);
  const [pageMode, setPageMode] = useStateIfMounted(PageModes.DEFAULT);
  const [authCheck, setAuthCheck] = useStateIfMounted(null);
  const [InvitePopupOpen, setInvitePopupOpen] = useStateIfMounted(false);
  const [CountryPopupOpen, setCountryPopupOpen] = useStateIfMounted(false);
  const [Country, setCountry] = useStateIfMounted("");
  const [hidePilotInvite, setHidePilotInvite] = useState(true);
  const [archiveMissionLoading, setArchiveMissionLoading] =
    useStateIfMounted(false);
  const [clientInfo, setClientInfo] = useStateIfMounted(null);
  const [currency, setCurrency] = useState(null);
  const [currentQuoteStatus, setCurrentQuoteStatus] = useState(null);
  const [missionPlanning, setMissionPlanning] = useState(null);
  const [operatorInfo, setOperatorInfo] = useState(null);

  const missionType = mission?.missionType;

  const getClientInfo = useCallback(async () => {
    if (mission?.clientId) {
      const client = await getClientById(firebase, mission?.clientId);
      setClientInfo(client);
    } else {
      const ClientData = {
        name: mission?.clientName,
        companyName: mission?.companyName,
        email: mission?.clientEmail,
        phone: mission?.clientPhone,
      };
      setClientInfo(ClientData);
    }
  }, [
    mission?.clientEmail,
    mission?.clientId,
    mission?.clientName,
    mission?.clientPhone,
    mission?.companyName,
    setClientInfo,
  ]);

  const getQuoteStatus = async () => {
    const quoteStatuscurrent = await getCurrentQuotesStatus(
      firebase,
      mission.id
    );
    setCurrentQuoteStatus(quoteStatuscurrent);
  };

  useEffect(() => {
    getClientInfo();
  }, [getClientInfo]);

  useEffect(() => {
    if (window.location.hash === "#communications") {
      setTabValue(
        mission?.missionType === missionTypeConstant.projectMission ||
          mission?.missionType === missionTypeConstant.clientOrder
          ? 3
          : 2
      );
    } else {
      setTabValue(0);
    }
  }, [pageMode, mission]);

  useEffect(() => {
    if (mission) {
      getCurrency(mission);
      if (mission?.id) {
        getQuoteStatus();
      }
      if (
        mission &&
        mission.assignedPilot &&
        (mission.missionType == missionTypeConstant.clientOrder ||
          mission.missionType == missionTypeConstant.projectMission)
      ) {
        fetchMissionPlanning(mission.id);
      }
    }
  }, [mission]);

  const fetchMissionPlanning = async (id) => {
    try {
      const data = await getMissionPlanningStatus(id);
      setMissionPlanning(data);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const getCurrency = async (mission) => {
    try {
      if (!mission || mission?.missionType != missionTypeConstant.clientOrder) {
        return null;
      }
      if (mission?.enterPriseId) {
        const enterprise = await getEnterPrise(mission?.enterPriseId);
        setCurrency(enterprise?.currency);
        return enterprise?.currency;
      } else if (mission?.clientId) {
        const client = await getClientById(firebase, mission.clientId);
        setCurrency(client?.currency);
        return client.currency;
      }
    } catch (e) {
      console.log("Error", e);
      return null;
    }
  };

  const getInvoice = useCallback(async () => {
    try {
      const deliveryPackage = await getDeliveryPackageByMissionId(
        mission?.id,
        firebase
      );
      const invoice = await getInvoiceByDeliveryPackageId(
        firebase,
        mission?.id,
        deliveryPackage
      );

      if (invoice?.length) {
        const filtered = invoice.filter(
          (doc) => doc.invoiceType === InvoiceText.firstInvoice
        );
        if (filtered.length) {
          setHidePilotInvite(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [mission?.id, setHidePilotInvite]);

  useEffect(() => {
    checkAdmintype(firebase).then((snapshot) => {
      if (
        (snapshot.adminType === admintype.regularAdmin ||
          snapshot.adminType === admintype.financeAdmin) &&
        (mission?.status === missionStatus.active ||
          mission?.status === missionStatus.completed)
      ) {
        setAuthCheck(false);
      } else {
        setAuthCheck(true);
      }
    });

    // disable invite buttons if invoice broken
    if (
      (mission?.status === missionStatus.confirmedMission ||
        mission?.status === missionStatus.unassigned) &&
      mission?.breakInvoice
    ) {
      getInvoice();
    }
  }, [getInvoice, mission, setAuthCheck]);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };
  const onCompleteMission = async () => {
    const invoiceData = {
      missionId: mission.id,
      mission_name: mission.missionName,
      pilotId: mission.assignedPilot,
      pilotName: mission.assignedPilotName,
      amount: mission.pilotPrice,
      pilotEditAccess: mission.mayPilotEditInvoice ? true : false,
    };
    await completeMission(
      firebase,
      missionID,
      deliveryPackages[deliveryPackages.length - 1].id,
      pilotPackages[pilotPackages.length - 1]?.id,
      mission
    );
    if (
      mission.missionType === missionTypeConstant.clientOrder ||
      mission.allowInvoice
    ) {
      await createInvoice(invoiceData);
    }
    refreshPilotPackages();
    refreshDeliveryPackages();
    refreshMission();
  };

  const onPackageReject = async (rejectedObj, pilotPackageID) => {
    await rejectPilotPackage(firebase, missionID, rejectedObj, pilotPackageID);
    refreshMission();
    refreshPilotPackages();
  };

  // const calculateProcessedPercentage = (total, processed) => {
  //   const result = Math.round((processed / total) * 100);
  //   return result;
  // };

  const handleAddToDelivery = async (selectedPilotFiles) => {
    const deliveryFiles = [...selectedPilotFiles];
    const batchSize = 500;
    //let count = 0;
    let processedCount = 0;

    while (deliveryFiles.length > 0) {
      let batchToProcess = deliveryFiles.splice(0, batchSize);
      await addDeliveryFileV2(
        {
          firebase,
          missionID,
          packageID: deliveryPackages[deliveryPackages.length - 1].id,
        },
        batchToProcess
      );
      processedCount = processedCount + batchToProcess.length;
      // count = calculateProcessedPercentage(selectedPilotFiles.length, processedCount);
      // dropzoneGalleryPilotRef.current.updateDeliveryCount(count);
    }
    // dropzoneGalleryPilotRef.current.updateDeliveryCount(0);
    return;
  };

  const checkMissionCanArchive = () => {
    if (mission.missionType == missionTypeConstant.testMission) {
      return true;
    } else if (
      mission.status != missionStatus.completed &&
      mission.status != missionStatus.archive
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePopupClose = () => {
    InvitePopupOpen && setInvitePopupOpen(false);
    CountryPopupOpen && setCountryPopupOpen(false);
  };

  const onAssignPilot = async ({ id, name }) => {
    await assignPilotToMission(firebase, id, name, mission);
    if (
      mission.clientId &&
      mission.missionType == missionTypeConstant.clientOrder
    ) {
      await createNotification(
        firebase,
        mission.clientId,
        "An operator is assigned to your order.",
        "An operator is assigned to your order.",
        `/app/client/orderDetails/${mission.id}`
      );
    }
    const interstUpdate = {
      status: interestInviteStatus.adminApproved,
    };
    await findAndUpdateInterest(firebase, missionID, id, interstUpdate);
    if (mission.missionType === missionTypeConstant.clientOrder) {
      let contract = await getMissionContract(id, missionID);
      if (contract?.status != contractStatusLabel.signed) {
        const contractData = {
          missionId: mission?.id,
          missionName: mission?.missionName,
          pilotId: id,
          pilotName: name,
          missionDeadline: mission?.deadline,
          pilotDeadline: mission?.deadline,
        };
        await createContract(contractData);
      }
    } else if (mission.missionType === missionTypeConstant.projectMission) {
      await assignPilotToProject(firebase, mission?.projectIds, id);
      getBasicProjectDetails(firebase, mission.projectIds)
        .then(async (data) => {
          if (data.status == "Success") {
            await createProjectContract({
              missions: [mission],
              pilot: { id, name },
              project: data.project,
            });
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
    let obj = {
      missionId: missionID,
      status: orderLogStatus.pilotAssigned,
    };
    createOrderStatus(firebase, obj)
      .then(() => {})
      .catch((e) => {
        console.log("Error", e);
      });
    setPageMode(PageModes.DEFAULT);
    refreshMission();
  };

  const checkInviteAssignDisablity = () => {
    return mission?.missionType === missionTypeConstant.clientOrder &&
      mission?.breakInvoice &&
      hidePilotInvite
      ? true
      : false;
  };

  const openPopup = () => {
    let country = mission.locationLabel.split(" ").slice(-1)[0];
    setCountry(country);
    if (countries.includes(country)) {
      setInvitePopupOpen(true);
    } else {
      setCountryPopupOpen(true);
    }
  };

  const GetMissionPlanningBanner = () => {
    let actionText = "";
    let actionSubText = ``;
    let tabNumber = 2;
    let bigText = false;
    let background;
    if (mission?.status == missionStatus.active) {
      if (missionPlanning?.status == missionPlanningStates.notFeasible) {
        // Show Location is not accessible
        actionText = "Location is not accessible";
        background = theme.palette.status.errorLight;
      } else if (missionPlanning?.status == missionPlanningStates.reviewed) {
        // show Waiting for operator feedback
        actionText = "Waiting for operator feedback";
        actionSubText =
          "We are waiting for the operator to review the mission again.";
        bigText = true;
      } else if (missionPlanning?.status == missionPlanningStates.reschedule) {
        // show Waiting for operator feedback
        actionText = `Mission is rescheduled to  : ${getDateTimeWithFormat(
          missionPlanning.data.currentFlightDate,
          "date-month-year"
        )}`;
        bigText = true;
      } else if (
        missionPlanning?.status == missionPlanningStates.readyToUpload
      ) {
        // show Waiting for operator feedback
        actionText = `Data collected!`;
        actionSubText = "Pilot has collected the drone data will upload soon.";
      } else if (
        missionPlanning?.data?.currentFlightDate &&
        new Date(missionPlanning?.data?.currentFlightDate) < new Date() &&
        mission?.pilotPackageStatus == PilotPackageStatus.open
      ) {
        actionText = "Mission delayed";
        background = theme.palette.status.errorLight;
      } else if (missionPlanning?.status == missionPlanningStates.onTime) {
        actionText = `Flying date set : ${getDateTimeWithFormat(
          missionPlanning.data.currentFlightDate,
          "date-month-year"
        )}`;
        actionSubText =
          "The drone operator will fly the drone on the above date to collect the data.";
      }
    } else if (
      mission.missionType === missionTypeConstant.clientOrder &&
      mission.paymentStatusClient == paymentStatus.unpaid &&
      mission.status == missionStatus.completed
    ) {
      actionText = "Payment is overdue";
      background = theme.palette.status.warningLight;
    }

    if (!actionText) {
      return null;
    }

    return (
      <UserNextActions
        actionText={actionText}
        actionSubText={actionSubText}
        tabNumber={tabNumber}
        background={background}
        bigText={bigText}
      />
    );
  };

  const MissionDetails = () => {
    const completeemptyMissionAdmin = async () => {
      await updatePilotPackageStatusForEmptyData({
        firebase,
        missionID: mission.id,
      });
      enqueueSnackbar("Site marked as completed successfully!", {
        variant: "success",
      });
      refreshMission();
    };
    return (
      <>
        {InvitePopupOpen && (
          <InvitePopup
            InvitePopupOpen={InvitePopupOpen}
            handlePopupClose={handlePopupClose}
            mission={mission}
            missionID={missionID}
            Country={Country}
            refreshMission={refreshMission}
          />
        )}
        {CountryPopupOpen && (
          <CountryPopup
            CountryPopupOpen={CountryPopupOpen}
            handlePopupClose={handlePopupClose}
            mission={mission}
            missionID={missionID}
            countries={countries}
            Country={Country}
            refreshMission={refreshMission}
          />
        )}
        {pageMode === PageModes.EDIT_MISSION && (
          <MissionDetailsEdit
            missionData={mission}
            submitMissionHandler={() => refreshMission()}
            onEditingFinished={() => {
              setPageMode(PageModes.DEFAULT);
            }}
          />
        )}
        {(pageMode === PageModes.ASSIGN_PILOTS ||
          pageMode === PageModes.INVITE_PILOTS) && (
          <>
            <Typography variant="h6" sx={singleMissionTitle}>
              SELECT DRONE OPERATOR
            </Typography>

            {pageMode === PageModes.ASSIGN_PILOTS && (
              <PilotSearch
                assignPilot={true}
                assignPilotHandler={async (data) => onAssignPilot(data)}
              />
            )}
            {pageMode === PageModes.INVITE_PILOTS && (
              <PilotSearch missionData={mission} />
            )}
          </>
        )}
        {pageMode === PageModes.DEFAULT && (
          <>
            {/* ------order detials according to new design------- */}
            <OrderDetailsComponent />
            {(mission.status === missionStatus.active ||
              mission.status != missionStatus.completed) &&
              !mission.archive &&
              mission?.missionType != missionTypeConstant.clientOrder && (
                <CreateInvoicePrompt
                  missionData={mission}
                  submitMissionHandler={() => refreshMission()}
                  onEditingFinished={() => {
                    setPageMode(PageModes.DEFAULT);
                  }}
                />
              )}

            <Box sx={styles.uploadInvoiceErrorMessage}>
              {checkInviteAssignDisablity() &&
                (mission.status === missionStatus.confirmedMission ||
                  mission.status === missionStatus.unassigned) &&
                mission.missionType === missionTypeConstant.clientOrder &&
                mission.subscriptionId === null && (
                  <Box variant="subtitle1">
                    {"*Please upload first invoice to assign/invite pilot"}
                  </Box>
                )}
            </Box>

            {mission?.projectIds &&
              mission?.allowEmptyData &&
              mission.status !== missionStatus.completed && (
                <Box sx={{ float: "right" }} mt={3}>
                  <AppButton
                    label="Complete mission"
                    onClick={() => completeemptyMissionAdmin()}
                  />
                </Box>
              )}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (mission?.assignedPilot && currentUser.role === userRoles.admin) {
      getPilotInfo();
    }
  }, [mission]);

  const getPilotInfo = async () => {
    const operatorInfo = await getPilot(firebase, mission.assignedPilot);
    setOperatorInfo(operatorInfo);
  };

  return (
    <Layout
      loading={
        loadingMission || loadingPilotPackages || loadingDeliveryPackages
      }
      className={styles.root}
    >
      <MissionContext.Provider
        value={{
          mission,
          currentUser,
          adminClientProgressStatus,
          refreshMission,
          checkInviteAssignDisablity,
          PageModes,
          setPageMode,
          openPopup,
          checkMissionCanArchive,
          archiveMissionLoading,
          setArchiveMissionLoading,
          handleTabChange,
          refreshPilotPackages,
          clientInfo,
          missionType,
          currentQuoteStatus,
          setCurrentQuoteStatus,
          missionPlanning,
          operatorInfo,
        }}
      >
        {!loadingMission &&
          !loadingPilotPackages &&
          !loadingDeliveryPackages &&
          (mission ? (
            <>
              {pageMode === PageModes.DEFAULT ? (
                <>
                  <OrderTopNavComponent />
                  <Box mt={3}>
                    {isAdmin &&
                    mission.status == missionStatus.initialClientOrder &&
                    currentQuoteStatus != quoteStatus.quoteSent &&
                    currentQuoteStatus != quoteStatus.declined ? (
                      <UserNextActions
                        actionSubText={AdminNextActions.sendQuote}
                      />
                    ) : isAdmin &&
                      mission.status == missionStatus.initialClientOrder &&
                      currentQuoteStatus === quoteStatus.declined ? (
                      <UserNextActions
                        actionSubText={AdminNextActions.declineQuote}
                        actionText="Quote been rejected"
                        background={theme.palette.status.warningLight}
                      />
                    ) : isAdmin &&
                      mission.missionType === missionTypeConstant.clientOrder &&
                      mission.status === missionStatus.unassigned &&
                      mission?.breakInvoice &&
                      hidePilotInvite ? (
                      <UserNextActions
                        actionText="Please upload the pre-delivery invoice."
                        bigText={true}
                        actionSubText={AdminNextActions.uploadClientPreInvoice}
                      />
                    ) : isAdmin &&
                      (mission.status === missionStatus.confirmedMission ||
                        mission.status === missionStatus.unassigned ||
                        mission.status != missionStatus.initialClientOrder) &&
                      mission.status != missionStatus.archive &&
                      mission.status != missionStatus.rejected &&
                      !mission?.assignedPilot ? (
                      <UserNextActions
                        actionSubText={AdminNextActions.invitePilots}
                        projctMission={mission?.projectIds ? true : false}
                      />
                    ) : isAdmin &&
                      mission.status == missionStatus.active &&
                      currentQuoteStatus === quoteStatus.accepted &&
                      missionPlanning?.status ===
                        missionPlanningStates.noData ? (
                      <UserNextActions
                        actionText={AdminNextActions.missionInReviewByPilot}
                      />
                    ) : isAdmin &&
                      mission?.pilotPackageStatus ===
                        PilotPackageStatus.pendingReview ? (
                      <UserNextActions
                        actionText="Verify the data"
                        actionSubText={AdminNextActions.verifyData}
                        tabNumber={
                          mission?.missionType ===
                          missionTypeConstant.clientOrder
                            ? 4 + pilotPackages.length
                            : 3 + pilotPackages.length
                        }
                      />
                    ) : isAdmin &&
                      mission.status === missionStatus.completed &&
                      mission.paymentStatusPilot === paymentStatus.unpaid &&
                      mission.missionType ===
                        missionTypeConstant.clientOrder ? (
                      <UserNextActions
                        actionText="Please update pilot payment status"
                        bigText={true}
                      />
                    ) : (
                      <>
                        {(mission?.missionType ==
                          missionTypeConstant.clientOrder ||
                          mission?.missionType ==
                            missionTypeConstant.projectMission) && (
                          <GetMissionPlanningBanner />
                        )}
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <PilotSearchBackNav
                  onClick={() => {
                    setPageMode(PageModes.DEFAULT);
                    refreshMission();
                  }}
                />
              )}

              <Box sx={styles.mainContent} mt={4}>
                <Box sx={styles.sectionContainer}>
                  <Box sx={styles.sectionContainer}>
                    <AppBar position="static" sx={styles.appBar} elevation={0}>
                      <StyledTabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                      >
                        <StyledTab
                          key="missionDetails"
                          label="Mission Details"
                          {...a11yProps(0)}
                        />
                        {mission.status != missionStatus.archive &&
                          mission.status != missionStatus.cancelled &&
                          mission.status != missionStatus.rejected &&
                          mission.missionType !=
                            missionTypeConstant.projectMission && (
                            <StyledTab
                              key="quotesInvoices"
                              label="quotes & Invoices"
                              {...a11yProps(1)}
                            />
                          )}
                        {(mission?.missionType ===
                          missionTypeConstant.projectMission ||
                          mission?.missionType ===
                            missionTypeConstant.clientOrder) && (
                          <StyledTab
                            key="missionPlanning"
                            label="Mission planning"
                            {...a11yProps(
                              mission?.missionType ===
                                missionTypeConstant.projectMission
                                ? 1
                                : 2
                            )}
                          />
                        )}
                        {!(
                          currentUser.role === userRoles.pilot ||
                          mission?.status === missionStatus.archive ||
                          mission?.status === missionStatus.rejected ||
                          mission?.status === missionStatus.cancelled
                        ) &&
                          (mission?.missionType ===
                            missionTypeConstant.clientOrder ||
                            mission?.missionType ===
                              missionTypeConstant.projectMission) && (
                            <StyledTab
                              key="communication"
                              label="Communication"
                              {...a11yProps(
                                mission?.missionType ===
                                  missionTypeConstant.clientOrder
                                  ? 3
                                  : 2
                              )}
                            />
                          )}
                        {mission.status != missionStatus.archive &&
                          mission.status != missionStatus.cancelled &&
                          pilotPackages.map((pilotPackage, index) => (
                            <StyledTab
                              key={pilotPackage.id}
                              label={
                                <Box sx={styles.packageTabLabel}>
                                  {pilotPackage.status ===
                                    PilotPackageStatus.rejected && (
                                    <BlockOutlined
                                      fontSize="small"
                                      style={{ marginRight: 8 }}
                                    />
                                  )}
                                  {pilotPackage.status ===
                                    PilotPackageStatus.approved && (
                                    <Done
                                      fontSize="small"
                                      style={{ marginRight: 8 }}
                                    />
                                  )}
                                  {pilotPackages.length > 1
                                    ? `Pilot Package ${index + 1}`
                                    : "Pilot Package"}
                                </Box>
                              }
                              {...a11yProps(index + 2)}
                            />
                          ))}

                        {mission.status != missionStatus.archive &&
                          mission.status != missionStatus.cancelled &&
                          mission?.status !== missionStatus.rejected &&
                          deliveryPackages?.map((deliveryPackage, index) => (
                            <StyledTab
                              key={deliveryPackage.id}
                              label={
                                <Box sx={styles.packageTabLabel}>
                                  {deliveryPackage.status ===
                                    DeliveryPackageStatus.failed && (
                                    <WarningAmberOutlined
                                      fontSize="small"
                                      style={{ marginRight: 8 }}
                                    />
                                  )}
                                  {deliveryPackage.status ===
                                    DeliveryPackageStatus.delivered && (
                                    <Done
                                      fontSize="small"
                                      style={{ marginRight: 8 }}
                                    />
                                  )}
                                  {deliveryPackages.length > 1
                                    ? `Delivery Package ${index + 1}`
                                    : "Delivery Package"}
                                </Box>
                              }
                              {...a11yProps(
                                mission?.missionType ===
                                  missionTypeConstant.clientOrder
                                  ? index + pilotPackages.length + 4
                                  : index + pilotPackages.length + 3
                              )}
                            />
                          ))}
                        {mission?.status === missionStatus.completed &&
                          mission?.missionType !==
                            missionTypeConstant.projectMission &&
                          mission?.missionType ===
                            missionTypeConstant.clientOrder && (
                            <StyledTab
                              key="feedback"
                              label="Client Feedback"
                              {...a11yProps(pilotPackages.length + 5)}
                            />
                          )}
                      </StyledTabs>
                    </AppBar>
                  </Box>
                </Box>
              </Box>

              <Box sx={styles.missioNDetails}>
                <TabPanel value={tabValue} index={0} key="missionDetails">
                  <MissionDetails />
                </TabPanel>

                {mission.status != missionStatus.archive &&
                  mission.status != missionStatus.cancelled &&
                  mission.status != missionStatus.rejected &&
                  mission.missionType != missionTypeConstant.projectMission && (
                    <TabPanel value={tabValue} index={1} key="quotesInvoices">
                      <QuoteDetail
                        mission={mission}
                        refreshMission={refreshMission}
                        currency={currency}
                      />
                    </TabPanel>
                  )}
                {(mission?.missionType === missionTypeConstant.projectMission ||
                  mission?.missionType === missionTypeConstant.clientOrder ||
                  mission?.missionType === missionTypeConstant.testMission) && (
                  <TabPanel
                    value={tabValue}
                    index={
                      mission?.missionType ===
                      missionTypeConstant.projectMission
                        ? 1
                        : 2
                    }
                    key="missionPlanning"
                  >
                    <MissionPlanning activeTab={missionPlanning?.data?.type} />
                  </TabPanel>
                )}

                {!(
                  currentUser.role === userRoles.pilot ||
                  mission?.status === missionStatus.archive ||
                  mission?.status === missionStatus.rejected ||
                  mission?.status === missionStatus.cancelled
                ) &&
                  (mission?.missionType === missionTypeConstant.clientOrder ||
                    mission?.missionType ===
                      missionTypeConstant.projectMission ||
                    mission?.missionType ===
                      missionTypeConstant.testMission) && (
                    <TabPanel
                      value={tabValue}
                      index={
                        mission?.missionType === missionTypeConstant.clientOrder
                          ? 3
                          : 2
                      }
                      key="communication"
                    >
                      {/* <Communication mission={mission} /> */}
                      <TwoWayCommunicationAdmin mission={mission} />
                    </TabPanel>
                  )}

                {pilotPackages?.map((pilotPackage, index) => (
                  <TabPanel
                    value={tabValue}
                    // index={
                    //   mission?.missionType ===
                    //     missionTypeConstant.projectMission ||
                    //   mission?.missionType === missionTypeConstant.clientOrder
                    //     ? index +
                    //       getTabIndex({ missionType: mission?.missionType })
                    //     : index +
                    //       getTabIndex({ missionType: mission?.missionType }) -
                    //       1
                    // }
                    index={
                      mission?.missionType === missionTypeConstant.clientOrder
                        ? index + 4
                        : index + 3
                    }
                    key={pilotPackage.id}
                  >
                    {authCheck === true ? (
                      <>
                        <PilotPackagesTab
                          packageId={pilotPackage.id}
                          mission={mission}
                          packageType={packageTypeConstant.pilotPackage}
                          onAddToDelivery={async (selectedPilotFiles) =>
                            await handleAddToDelivery(selectedPilotFiles)
                          }
                          mediaPackage={pilotPackage}
                          isEditable={false}
                          onPackageReject={async (rejectedData) =>
                            await onPackageReject(rejectedData, pilotPackage.id)
                          }
                          isAdmin={true}
                        />
                      </>
                    ) : (
                      <Box sx={styles.unAuthorized}>
                        <StatusAlertContainer
                          heading="Unauthorized access! Your admin type does not allow you to work with mission data.
                          Please contact Napoleon to change your access rights."
                          severity={statusAlertSeverity.error}
                          coloredBackground
                        />
                      </Box>
                    )}
                  </TabPanel>
                ))}

                {deliveryPackages?.map((deliveryPackage, index) => (
                  <TabPanel
                    value={tabValue}
                    // index={
                    //   mission?.missionType ===
                    //     missionTypeConstant.projectMission ||
                    //   mission?.missionType === missionTypeConstant.clientOrder
                    //     ? index +
                    //       pilotPackages.length +
                    //       getTabIndex({ missionType: mission?.missionType })
                    //     : index +
                    //       pilotPackages.length +
                    //       getTabIndex({ missionType: mission?.missionType }) -
                    //       1
                    // }
                    index={
                      mission?.missionType === missionTypeConstant.clientOrder
                        ? index + pilotPackages.length + 4
                        : index + pilotPackages.length + 3
                    }
                    key={deliveryPackage.id}
                  >
                    {authCheck === true ? (
                      <>
                        {mission.allowEmptyData ? (
                          <EmptyMessage
                            currentUser={currentUser}
                            mission={mission}
                          />
                        ) : (
                          <DeliveryPackagesTab
                            packageId={deliveryPackage.id}
                            mission={mission}
                            packageType={packageTypeConstant.deliveryPackage}
                            mediaPackage={deliveryPackage}
                            isEditable={
                              deliveryPackage.status !==
                              DeliveryPackageStatus.delivered
                            }
                            onCompleteMission={async () =>
                              await onCompleteMission()
                            }
                            isAdmin={true}
                          />
                        )}
                      </>
                    ) : (
                      <Box sx={styles.unAuthorized}>
                        <StatusAlertContainer
                          heading="Unauthorized access! Your admin type does not allow you to work with mission data.
                          Please contact Napoleon to change your access rights."
                          severity={statusAlertSeverity.error}
                          coloredBackground
                        />
                      </Box>
                    )}
                  </TabPanel>
                ))}
                {mission?.status === missionStatus.completed &&
                  mission?.missionType !== missionTypeConstant.projectMission &&
                  mission?.missionType === missionTypeConstant.clientOrder && (
                    <TabPanel
                      value={tabValue}
                      index={pilotPackages.length + 5}
                      key="feedback"
                    >
                      <Box m={2}>
                        <FeedbackOrder
                          data={mission}
                          type={orderCollectionData.missions}
                          refreshPage={refreshMission}
                        />
                      </Box>
                    </TabPanel>
                  )}
              </Box>
            </>
          ) : (
            ""
          ))}
      </MissionContext.Provider>
    </Layout>
  );
};

AdminSingleMissionPage.propTypes = {
  missionID: PropTypes.string.isRequired,
};

export default AdminSingleMissionPage;
