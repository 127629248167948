import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import GlobalTable from "src/components/app/admin/GlobalTable";
import DownloadProjectMission from "src/components/app/missions/FilesPackages/component/DownloadProjectMission";
import AppButton from "src/components/global/elements/AppButton";
import StatusBadge from "src/components/global/elements/StatusBadge";
import {
  areaUnits,
  areaUnitV2,
  colorStatus,
  OrderTypeConstant,
} from "src/lib/constants";
import theme from "src/theme";

const styles = {
  projectDroneOperatorsMainContainer: {
    marginTop: "40px !important",
  },
  contentBox: {
    boxShadow: "none",
    filter: `drop-shadow(0 3pt 66pt ${theme.palette.grey.lightSpray})`,
    background: theme.palette.grey.white,
    marginTop: "20px",
  },
  blackText: {
    color: `${theme.palette.black.darkSeaBlack} !important`,
  },
  noDataText: {
    textAlign: "center",
    margin: "20px",
    padding: "40px 20px",
    color: theme.palette.secondary.main,
  },
  warningText: {
    color: theme.palette.status.warningDark,
  },
};

const DownloadProjectDataTable = ({
  title,
  allData,
  columns,
  loading,
  noDataAvailableText,
  // redirectURL,
  onClick,
  project,
}) => {
  const [dataToShow, setDataToShow] = useState([]);
  const [limit, setLimit] = useState(5);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [lastPageNumber, setLastPageNumber] = useState(null);

  useEffect(() => {
    setDataToShow(allData?.slice(0, limit));
    let pageNumberNew = currentPageNumber;
    if (allData?.length <= pageNumberNew * limit) {
      setIsLastPage(true);
      setLastPageNumber(pageNumberNew);
    }
  }, [limit, allData]);

  const valueConvertHandler = (field, row) => {
    switch (field) {
      case "missionStatus": {
        const allMissionCount = row.missionCountForPilot || 0;
        const completedMissioncount = row.completedMissionCountForPilot || 0;
        return (
          <StatusBadge
            name={completedMissioncount + "/" + allMissionCount}
            status={
              completedMissioncount == allMissionCount
                ? colorStatus.green
                : colorStatus.red
            }
          />
        );
      }

      case "areaSize": {
        let area = row?.areaSize || 0;
        area = Number.isInteger(area) ? area : area.toFixed(2);

        if (row?.type == OrderTypeConstant.area) {
          area = area + " " + areaUnits.sqkm;
        } else if (row?.type == OrderTypeConstant.linear) {
          area = area + " " + areaUnitV2.km;
        } else {
          area = "N/A";
        }

        return <Typography variant="body1">{area}</Typography>;
      }

      case "action": {
        return onClick ? (
          <AppButton
            look="negative"
            small
            noIcon
            onClick={() => onClick(row)}
            label="Delete"
          />
        ) : (
          <></>
        );
      }
    }
  };

  const limitHandler = (value) => {
    setIsLastPage(false);
    setLastPageNumber(null);
    setLimit(value);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPageNumber;
    let createDataInstance = allData;
    if (type == "next") {
      if (!(lastPageNumber === currentPageNumber)) {
        pageNumberNew = pageNumberNew + 1;
        setDataToShow(
          createDataInstance.slice(
            (pageNumberNew - 1) * limit,
            limit * pageNumberNew
          )
        );
      }
    } else {
      if (pageNumberNew > 1) {
        pageNumberNew = pageNumberNew - 1;
        setDataToShow(
          createDataInstance.slice(
            (pageNumberNew - 1) * limit,
            limit * pageNumberNew
          )
        );
      }
    }
    if (allData?.length <= pageNumberNew * limit) {
      setIsLastPage(true);
      setLastPageNumber(pageNumberNew);
    } else {
      setIsLastPage(false);
      setLastPageNumber(null);
    }
    setCurrentPageNumber(pageNumberNew);
  };

  return (
    <Box sx={styles.projectDroneOperatorsMainContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{title || "NO TITLE"}</Typography>
        <DownloadProjectMission
          projectMissions={allData}
          project={{
            projectId: project?.projectId,
            projectName: project?.projectName,
          }}
        />
      </Box>

      <Box sx={styles.contentBox}>
        {allData?.length > 0 ? (
          <GlobalTable
            columns={columns}
            allData={dataToShow}
            valueConvertHandler={valueConvertHandler}
            // goToPageFcn={goToPageFcn}
            loading={loading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPageNumber}
            isLastPage={isLastPage}
            lastPage={lastPageNumber}
          />
        ) : (
          <Typography variant="h6" sx={styles.noDataText}>
            {noDataAvailableText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

DownloadProjectDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  allData: PropTypes.array,
  noDataAvailableText: PropTypes.string,
  // redirectURL: PropTypes.string,
  onClick: PropTypes.func,
  project: PropTypes.object,
  triggerReload: PropTypes.bool,
  setTriggerReload: PropTypes.func,
  enableMarkAllRead: PropTypes.func,
};

DownloadProjectDataTable.defaultProps = {
  loading: true,
  allData: [],
  noDataAvailableText: "",
  // redirectURL: "#",
  onClick: undefined,
  project: undefined,
  triggerReload: undefined,
  setTriggerReload: undefined,
  enableMarkAllRead: false,
};

export default DownloadProjectDataTable;
