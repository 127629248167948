import React from "react";
import Paper from "@mui/material/Paper";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);
import { Bar } from "react-chartjs-2";
import theme from "../../../theme";
import PropTypes from "prop-types";

const styles = {
  styleGraph: {
    width: "100%",
    "&.MuiPaper-root": {
      width: "100%",
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
    "& .jss1": {
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
    "& .Component-root-1": {
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
    "& .center-container": {
      height: { xs: "174px", sm: "250px", md: "250px" },
    },
  },
};

const Graph = ({ barData = [], valueField, argumentField }) => {
  const data = {
    labels: barData.length
      ? barData.map((item) => item[argumentField])
      : ["No Data"],
    datasets: [
      {
        label: "Bar Chart",
        data: barData.length ? barData.map((item) => item[valueField]) : [0],
        backgroundColor: theme.palette.secondary.lavender,
        borderColor: theme.palette.secondary.lavender,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: {
        display: !barData.length,
        text: "No Data Available",
      },
    },
    scales: {
      x: { title: { display: false, text: argumentField } },
      y: { beginAtZero: true, title: { display: false, text: valueField } },
    },
  };
  return (
    <Paper sx={styles.styleGraph}>
      {barData && (
        <div style={{ width: "100%", height: "100%" }}>
          <Bar data={data} options={options} />
        </div>
      )}
    </Paper>
  );
};

Graph.propTypes = {
  barData: PropTypes.array.isRequired,
  valueField: PropTypes.string.isRequired,
  argumentField: PropTypes.string.isRequired,
};

export default Graph;
