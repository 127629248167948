import PropTypes from "prop-types";
import React, { useState } from "react";
import theme from "../../theme";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const styles = {
  globalErrorMessage: {
    background: "rgba(0, 0, 0, 0.3)",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: 100000,
  },

  fadeIn: {
    visibility: "visible",
    opacity: 1,
    animation: `$fadeIn ease 0.3s`,
  },
  fadeOut: {
    animation: `$fadeOut ease 0.3s`,
  },

  errorWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    background: theme.palette.black.darkSeaBlack,
    width: "100%",
    color: theme.palette.grey.lightWarmGrey,
    padding: { xs: "21px 35px 21px 15px", lg: "29px 30px 33px" },
    "& p": {
      float: "left",
      marginBottom: "0",
    },
  },
  errorMessage: {
    width: { xs: "64%", md: "100%" },
    marginLeft: { xs: "calc(30% + 15px)" },
    color: theme.palette.grey.white,
  },
  errorMessageLabel: {
    float: "left",
    marginBottom: 0,
    fontWeight: 500,
    width: { xs: "30%", md: "100%" },
    marginRight: { xs: "15px", md: "30px" },
    top: { xs: "50%", md: "100%" },
    position: { xs: "absolute", md: "relative" },
    transform: { xs: "translateY(-50%)" },
    color: theme.palette.grey.white,
  },
  close: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    paddingLeft: "10px",
    color: theme.palette.grey.white,
    "&:hover": {
      cursor: "pointer",
    },
    "& span": {
      width: "1px",
      display: "inline-block",
      position: "absolute",
      background: theme.palette.grey.lightWarmGrey,
      " &:first-child": {
        transform: "rotate(45deg)",
      },

      "&:last-child": {
        transform: "rotate(-45deg)",
      },
    },
    height: { xs: "14px", lg: "20px" },
    right: { xs: "15px", lg: "50px" },
    width: { lg: "20px" },
  },
  "@keyframes fadeOut": {
    "0%": {
      opacity: "1",
    },
    "100%": {
      opacity: "0",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: "0",
    },
    "100%": {
      opacity: "1",
    },
  },
};

const ErrorMessage = ({
  errorMessage = "",
  errorMessageLabel = "",
  closeTriggered = undefined,
}) => {
  const [closed, closeMessage] = useState(false);

  const handleClick = () => {
    closeMessage(true);
  };
  if (closed) {
    closeTriggered(true);
  }

  return (
    <Box
      sx={
        closed
          ? { ...styles.fadeOut, ...styles.globalErrorMessage }
          : { ...styles.fadeIn, ...styles.globalErrorMessage }
      }
    >
      <Box sx={styles.errorWrapper}>
        {errorMessageLabel ? (
          <Typography variant="h5" style={{ ...styles.errorMessageLabel }}>
            {errorMessageLabel}
          </Typography>
        ) : null}
        {errorMessage ? (
          <Typography variant="body1" style={{ ...styles.errorMessage }}>
            {errorMessage}
          </Typography>
        ) : null}

        <Box sx={styles.close} onClick={handleClick}>
          <CloseIcon fontSize="medium" />
          <span></span>
          <span></span>
        </Box>
      </Box>
    </Box>
  );
};

ErrorMessage.propTypes = {
  show: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorMessageLabel: PropTypes.string,
  closeTriggered: PropTypes.func.isRequired,
};

// ErrorMessage.defaultProps = {
//   show: false,
//   errorMessage: "",
//   errorMessageLabel: "",
// };

export default ErrorMessage;
