import React, { useState } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Box, Badge } from "@mui/material";
import theme from "../../../theme";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import TopMenuIcons from "./TopMenuIcons";
import CartPopup from "../../app/NewDataLibrary/components/CartPopup";

const styles = {
  cartBox: {
    position: "relative",
  },
  cartNumStyle: {
    position: "absolute",
    right: "4px",
    top: "-10px",
    backgroundColor: theme.palette.status.warningDark,
    fontSize: "9px",
    width: "17px",
    height: "17px",
    fontWeight: "500",
    color: theme.palette.grey.white,
    display: "inline",
    textAlign: "center",
    padding: "3px",
    borderRadius: "100%",
  },
  iconBox: { display: "flex", justifyContent: "flex-start" },
  totalStyle: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    fontSize: "10px",
    minWidth: "16px",
    minHeight: "16px",
    margin: "auto",
    fontWeight: 500,
  },
};

const AddCart = ({ cartData = {} }) => {
  const [anchorElCart, setAnchorElCart] = useState(null);

  const id = "cart-panel";
  let totalCartData = cartData?.carts.length || "0";

  const handleClickCart = (event) => {
    setAnchorElCart(event.currentTarget);
  };

  const handleCloseCart = () => {
    setAnchorElCart(null);
  };

  return (
    <>
      <Box sx={styles.cartBox} onClick={handleClickCart} aria-describedby={id}>
        <Box sx={styles.iconBox}>
          <Box component={"span"} ml={1}>
            <TopMenuIcons icon={ShoppingCartOutlinedIcon} addBackgroundOval />
          </Box>
          <Box sx={styles.totalStyle}>
            <Badge badgeContent={totalCartData} color="primary"></Badge>
          </Box>
        </Box>
      </Box>

      <CartPopup
        anchorElCart={anchorElCart}
        setAnchorElCart={setAnchorElCart}
        handleClickCart={handleClickCart}
        handleCloseCart={handleCloseCart}
        cartId={id}
      />
    </>
  );
};
AddCart.propTypes = {
  cartData: PropTypes.object,
};
// AddCart.defaultProps = {
//   cartData: {},
// };
export default AddCart;
