import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";
import { Box } from "@mui/material";
import AppButton from "../../global/elements/AppButton";
import { confirmPilotClicked } from "../../../services/pilots";
import Rating from "@mui/material/Rating";
import { getUser } from "src/services/auth";
import { userRoles } from "../../../lib/constants";

const myStyles = {
  root: {
    border: { xs: `1px solid ${theme.palette.grey.lightWarmGrey}`, lg: "none" },
    borderBottom: { lg: `1px solid ${theme.palette.grey.lightWarmGrey}` },
    padding: { xs: "0", sm: "1rem", lg: "1rem 0" },
    margin: { xs: "0", sm: "1rem", lg: "0 1rem" },
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    background: theme.palette.grey.white,
    "& >li": {
      width: { xs: "100%", lg: "14.28%" },
      padding: "1rem",
      borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      lineHeight: "1.5em",
      border: { lg: "none" },
    },
    "& >li:last-child": {
      borderBottom: "none",
    },
    "& ul": {
      margin: "0",
      listStyle: "none",
      textAlign: { xs: "right", lg: "left" },
      lineHeight: "1.5em",
    },
  },
  plabel: {
    float: "left",
    fontWeight: "500",
    display: { lg: "none" },
  },
  data: {
    float: { xs: "right", lg: "initial" },
  },
  name: {
    fontWeight: "500",
    color: theme.palette.black.darkSeaBlack,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.black.darkSeaBlack,
    },
  },
  purpleDot: {
    float: "left",
    width: "2px",
    height: "2px",
    margin: "auto 0",
    marginRight: "15px",
    padding: "5px",
    marginTop: "5px",
    borderRadius: "50%",
    background: theme.palette.primary.main,
  },
  redDot: {
    float: "left",
    width: "2px",
    height: "2px",
    margin: "auto 0",
    marginRight: "15px",
    padding: "5px",
    marginTop: "5px",
    borderRadius: "50%",
    background: theme.palette.status.errorLight,
  },
};

const PilotInviteListItem = ({
  pilot = undefined,
  className = "",
  invitePilotHandler = undefined,
  uninvitePilotHandler = undefined,
  isInvited = undefined,
  hasDeclined = undefined,
}) => {
  const availableLocations = (
    <ul>
      {pilot &&
        pilot?.availableLocations &&
        Array.isArray(pilot?.availableLocations) &&
        pilot?.availableLocations?.map((item) => <li key={item}>{item}</li>)}
    </ul>
  );
  const sensorsCameras = (
    <ul>
      {pilot &&
        pilot?.sensorsCameras &&
        Array.isArray(pilot?.sensorsCameras) &&
        pilot?.sensorsCameras?.map((item) => <li key={item}>{item}</li>)}
    </ul>
  );
  const mappingProcessing = (
    <ul>
      {pilot &&
        pilot?.mappingProcessing &&
        Array.isArray(pilot?.mappingProcessing) &&
        pilot?.mappingProcessing?.map((item) => <li key={item}>{item}</li>)}
    </ul>
  );
  const onUninvitePilotClicked = (id) => {
    if (confirmPilotClicked("uninvite")) uninvitePilotHandler(id);
  };

  return (
    <Box sx={{ ...className, ...myStyles.root }}>
      <Box component="li">
        <Link style={myStyles.name} to={`/app/pilots/${pilot.id}`}>
          {pilot.name}
        </Link>
      </Box>
      <Box component="li">
        <Box component="span" sx={myStyles.plabel}>
          Email
        </Box>
        <Box component="span" sx={myStyles.data}>
          <a href={`mailto:${pilot.email}`}>{pilot.email}</a>
        </Box>
      </Box>
      <Box component="li">
        <Box component="span" sx={myStyles.plabel}>
          Available locations
        </Box>
        <Box component="span" sx={myStyles.data}>
          {availableLocations}
        </Box>
      </Box>
      <Box component="li">
        <Box component="span" sx={myStyles.plabel}>
          Sensors / Cameras
        </Box>
        <Box component="span" sx={myStyles.data}>
          {sensorsCameras}
        </Box>
      </Box>
      <Box component="li">
        <Box component="span" sx={myStyles.plabel}>
          Mapping / Processing
        </Box>
        <Box component="span" sx={myStyles.data}>
          {mappingProcessing}
        </Box>
      </Box>
      <Box component="li">
        <Box component="span" sx={myStyles.plabel}>
          Rating
        </Box>
        <Box component="span" sx={myStyles.data}>
          <Rating
            value={pilot?.ratings?.overallRating || 0}
            readOnly
            precision={0.5}
          />
        </Box>
      </Box>
      <Box component="li">
        {!hasDeclined && !isInvited && (
          <AppButton
            onClick={() => invitePilotHandler(pilot.id)}
            look="green"
            label="Invite"
          />
        )}
        {!hasDeclined && isInvited && (
          <>
            <AppButton
              onClick={() => onUninvitePilotClicked(pilot.id)}
              look="negative"
              label="Uninvite"
            />
          </>
        )}
        {hasDeclined && (
          <>
            <Box component="span" sx={myStyles.redDot} />
            <Box component="span">Has declined</Box>
          </>
        )}
      </Box>
    </Box>
  );
};

PilotInviteListItem.propTypes = {
  pilot: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    availableLocations: PropTypes.arrayOf(PropTypes.string),
    mappingProcessing: PropTypes.arrayOf(PropTypes.string),
    sensorsCameras: PropTypes.arrayOf(PropTypes.string),
    dateCreated: PropTypes.string.isRequired,
    ratings: PropTypes.shape({
      overallRating: PropTypes.number.isRequired,
      priceRating: PropTypes.number.isRequired,
      communicationRating: PropTypes.number.isRequired,
      pmRating: PropTypes.number.isRequired,
      pdRating: PropTypes.number.isRequired,
    }),
    id: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  invitePilotHandler: PropTypes.func.isRequired,
  isInvited: PropTypes.bool.isRequired,
  hasDeclined: PropTypes.bool.isRequired,
  uninvitePilotHandler: PropTypes.func.isRequired,
};

export default PilotInviteListItem;
