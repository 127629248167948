import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {
  accordionMenuItems,
  sideMenuOptionText,
  menuItemsWithIcons,
  disableAccordionMenuItems,
} from "../../../lib/styleConstants";

const styles = {
  optionIcon: {
    marginRight: "10px",
  },
  optionSubMenuIcon: {
    marginRight: "6px",
    width: "20px",
    height: "20px",
  },
  missionMenuItemActive: {
    color: theme.palette.secondary.lightPurple,
    "& .MuiTypography-root": {
      color: `${theme.palette.secondary.lightPurple} !important`,
    },
    "&:hover": {
      color: theme.palette.secondary.lightPurple,
      opacity: 1,
    },
  },
  optionSubMenuText: {
    marginTop: "0px",
    //fontSize: "0.8rem",
  },
  accordianCls: {
    background: "unset",
    boxShadow: "none",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  accordianActiveCls: {
    color: theme.palette.secondary.lightPurple,
    "& .MuiTypography-root": {
      color: `${theme.palette.secondary.lightPurple} !important`,
    },
    background: theme.palette.grey.white,
    padding: "0px 15px",
    // color: `${theme.palette.secondary.lightPurple} !important`,
    "&:hover": {
      color: theme.palette.secondary.lightPurple,
      opacity: 1,
    },
  },

  disbledAccordian: {
    "&.MuiAccordion-root": {
      "&.Mui-disabled": {
        background: "inherit",
      },
    },
  },
};

const SideMenuOption = ({
  title = undefined,
  link = undefined,
  hasSubMenu = undefined,
  subMenus = undefined,
  anotherWindow = false,
  disabled = false,
}) => {
  const location = window.location.pathname;
  const expandState = getExpandState() ? true : false;
  const [accordianExpand, setAccordianExpand] = useState(expandState);

  function getExpandState() {
    let pathFound = false;
    if (hasSubMenu) {
      let menus = Object.values(subMenus);
      pathFound = menus.find((m) => m == location);
    }
    return pathFound ? true : false;
  }

  const handleAccordian = (e) => {
    e.stopPropagation();
    setAccordianExpand(!accordianExpand);
  };

  const getSubMenuTittle = (key) => {
    if (key == "initialClientOrder") {
      return "Initial Order";
    } else if (key == "Unassigned") {
      return "Confirmed";
    }
    return key;
  };

  return (
    <>
      {hasSubMenu ? (
        <>
          <Box sx={{ width: "100%" }}>
            <Accordion
              sx={
                disabled
                  ? { ...styles.disbledAccordian, ...styles.accordianCls }
                  : { ...styles.accordianCls }
              }
              expanded={accordianExpand}
              onChange={handleAccordian}
              disabled={disabled}
            >
              <AccordionSummary
                style={
                  getExpandState()
                    ? {
                        ...accordionMenuItems,
                        ...styles.accordianActiveCls,
                      }
                    : disabled
                    ? { ...disableAccordionMenuItems, color: "inherit" }
                    : { ...accordionMenuItems }
                }
                expandIcon={<ExpandMore fontSize="medium" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                disabled={disabled}
              >
                <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
                  {title}
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <Grid container direction="column">
                  {Object.keys(subMenus)?.map((key, index) => {
                    return (
                      <Link
                        key={index}
                        to={subMenus[key]}
                        style={menuItemsWithIcons}
                        activeStyle={styles.missionMenuItemActive}
                        partiallyActive={true}
                        onClick={handleAccordian}
                      >
                        <Typography
                          variant="subtitle1Med"
                          sx={styles.optionSubMenuText}
                        >
                          {getSubMenuTittle(key)}
                        </Typography>
                      </Link>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      ) : !anotherWindow ? (
        <Link
          to={link}
          style={disabled ? disableAccordionMenuItems : accordionMenuItems}
          activeStyle={{
            ...accordionMenuItems,
            ...styles.accordianActiveCls,
            padding: "15px",
            maxHeight: "unset",
          }}
          partiallyActive={true}
        >
          <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
            {title}
          </Typography>
        </Link>
      ) : (
        <a
          href={link}
          style={disabled ? disableAccordionMenuItems : accordionMenuItems}
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="subtitle1Med" sx={sideMenuOptionText}>
            {title}
          </Typography>
        </a>
      )}
    </>
  );
};

SideMenuOption.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  hasSubMenu: PropTypes.bool.isRequired,
  subMenus: PropTypes.object,
  anotherWindow: PropTypes.bool,
  disabled: PropTypes.bool,
};
// SideMenuOption.defaultProps = {
//   subMenus: undefined,
//   anotherWindow: false,
//   disabled: false,
// };

export default SideMenuOption;
